import { Map, Record, Set } from 'immutable';

import { simpleAction } from '../../common/utils/reduxUtils';

const InitialState = Record({
  dialogsOpen: Set(),
  dialogsData: Map(),
});
const INITIAL_STATE = InitialState();

const SET_DIALOG_OPEN = 'SET_DIALOG_OPEN';
const SET_DIALOG_DATA = 'SET_DIALOG_DATA';
const CLEAR_DIALOGS = 'CLEAR_DIALOGS';

export function dialogsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DIALOG_OPEN:
      // OPEN
      if (action.open) {
        return state
          .update('dialogsOpen', dialogsOpen => dialogsOpen.add(action.spec))
          .update('dialogsData', dialogsData =>
            dialogsData.set(action.spec, action.data)
          );
      }
      // CLOSE
      return state
        .update('dialogsOpen', dialogsOpen => dialogsOpen.remove(action.spec))
        .update('dialogsData', dialogsData => dialogsData.delete(action.spec));

    case SET_DIALOG_DATA:
      return state.update('dialogsData', dialogsData =>
        dialogsData.set(action.spec, action.data)
      );
    case CLEAR_DIALOGS:
      return state.clear();
    default:
      return state;
  }
}

export const getIsDialogOpen = (id, spec) => state =>
  state.dialogs.dialogsOpen.has(Map({ id, ...spec }));
export const getDialogData = (id, spec) => state =>
  state.dialogs.dialogsData.get(Map({ id, ...spec }));

export function openDialog({ id, spec, data }) {
  return {
    type: SET_DIALOG_OPEN,
    open: true,
    spec: Map({ id, ...spec }),
    data,
  };
}

export function closeDialog({ id, spec }) {
  return {
    type: SET_DIALOG_OPEN,
    open: false,
    spec: Map({ id, ...spec }),
  };
}

export function setDialogData({ id, spec, data }) {
  return {
    type: SET_DIALOG_DATA,
    spec: Map({ id, ...spec }),
    data,
  };
}

export const clearDialogs = simpleAction(CLEAR_DIALOGS);
