"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.easeInOutBack = exports.easeBackIn = exports.easeOutElastic = exports.easeInOutSine = exports.easeOutQuad = exports.easeInQuad = exports.linear = void 0;
var linear = function (t, b, c, d) {
    return (c * t) / d + b;
};
exports.linear = linear;
var easeInQuad = function (t, b, c, d) { return c * (t /= d) * t + b; };
exports.easeInQuad = easeInQuad;
var easeOutQuad = function (t, b, c, d) { return -c * (t /= d) * (t - 2) + b; };
exports.easeOutQuad = easeOutQuad;
var easeInOutSine = function (t, b, c, d) { return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b; };
exports.easeInOutSine = easeInOutSine;
var easeOutElastic = function (t, b, c, d) {
    var s = 1.70158;
    var p = 0;
    var a = c;
    if (t === 0)
        return b;
    if ((t /= d) === 1)
        return b + c;
    if (!p)
        p = d * 0.3;
    if (a < Math.abs(c)) {
        a = c;
        s = p / 4;
    }
    else
        s = (p / (2 * Math.PI)) * Math.asin(c / a);
    return (a * Math.pow(2, -10 * t) * Math.sin(((t * d - s) * (2 * Math.PI)) / p) +
        c +
        b);
};
exports.easeOutElastic = easeOutElastic;
var easeBackIn = function (t, b, c, d) {
    var s = 1.70158;
    return c * (t /= d) * t * ((s + 1) * t - s) + b;
};
exports.easeBackIn = easeBackIn;
var easeInOutBack = function (t, b, c, d) {
    var s = 1.70158;
    if ((t /= d / 2) < 1)
        return (c / 2) * (t * t * (((s *= 1.525) + 1) * t - s)) + b;
    return (c / 2) * ((t -= 2) * t * (((s *= 1.525) + 1) * t + s) + 2) + b;
};
exports.easeInOutBack = easeInOutBack;
