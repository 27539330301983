import Scrollbars from 'react-custom-scrollbars';

import Footer from '../containers/Footer';
import styles from '../styles/pages/BasePage.module.less';

export default function BasePage({ header, children }) {
  return (
    <div className={styles.Container}>
      {header}
      <div className={styles.Content}>
        <Scrollbars
          style={{ flex: 1 }}
          renderView={props => (
            <div {...props} className={styles['ScrollbarsView']} />
          )}
          autoHide
        >
          {children}
        </Scrollbars>
      </div>
      <Footer />
    </div>
  );
}
