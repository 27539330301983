import {
  BranchesOutlined,
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  HighlightFilled,
  HighlightOutlined,
  LikeOutlined,
  MehOutlined,
  MinusSquareOutlined,
  NodeExpandOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  PullRequestOutlined,
  SaveOutlined,
  ShareAltOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import axios from 'axios';
import classNames from 'classnames';
import { writeText } from 'clipboard-polyfill/text';
import { Form, Formik } from 'formik';
import { List } from 'immutable';
import {
  groupBy,
  isFunction,
  isNumber,
  mapValues,
  noop,
  pick,
  sortBy,
} from 'lodash-es';
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import apiRoutes from '../app/apiRoutes';
import { useBoardData, useBoardSelfRights } from '../app/contexts/BoardContext';
import {
  makeSorter,
  useDataOptionsContext,
} from '../app/contexts/DataOptionsContext';
import { HighlightContext } from '../app/contexts/HighlightContext';
import { BoardColumnsMetadata } from '../app/enums/BoardColumn';
import { BoardColumnsByType } from '../app/enums/BoardType';
import { getUser } from '../app/redux/auth';
import { queryClient } from '../appSingletons';
import { useDebouncedVariable } from '../common/utils/hookUtils';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import {
  showConfirmModal,
  showSuccessMessage,
} from '../common/utils/messageUtils';
import { cssTransitionClassNames } from '../common/utils/reactTransitionUtils';
import { useStateIfMounted } from '../common/utils/reactUtils';
import { useScrollToThis } from '../common/utils/scrollUtils';
import FormField from '../components/forms/FormField';
import { useScreenshots } from '../components/screenshots';
import { useLocalizedYup } from '../config/intl/LocaleProvider';
import styles from '../styles/containers/BoardColumns.module.less';
import ShortcutCardButton from './ShortcutCardButton';
import TrelloCardButton from './TrelloCardButton';

const CARD_INITIAL_VALUES = { text: '' };

const MergeContext = createContext({
  initiatingMergeCardId: null,
  setInitiatingMergeCardId: noop,
});

const fetchCreateCard = axiosToQueryFn(({ boardId, column, text }) =>
  axios.post(apiRoutes.createBoardCard(boardId), { column, text })
);

const fetchEditCard = axiosToQueryFn(
  ({ boardId, cardId, cardContentId, text }) =>
    axios.put(apiRoutes.editBoardCardContent(boardId, cardId, cardContentId), {
      text,
    })
);

const fetchDeleteCard = axiosToQueryFn(({ boardId, cardId, cardContentId }) =>
  axios.delete(apiRoutes.deleteBoardCardContent(boardId, cardId, cardContentId))
);

function CardForm({
  initialValues,
  fetchFn,
  fetchKey,
  mutateArgs,
  onSuccess,
  buttonIcon,
  keyActions,
}) {
  const { _id: boardId } = useBoardData();

  const yup = useLocalizedYup();
  const validationSchema = yup.object().shape({
    text: yup.string(),
  });
  const { mutate, isLoading } = useMutation({
    mutationFn: fetchFn,
    mutationKey: [fetchKey, { boardId }],
    onSuccess: async (data, { resetForm }) => {
      resetForm();
      if (isFunction(onSuccess)) {
        onSuccess();
      }
      await queryClient.refetchQueries('board');
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        if (!values.text) {
          return;
        }
        await mutate({ boardId, text: values.text, resetForm, ...mutateArgs });
      }}
    >
      {({ handleSubmit }) => (
        <Form>
          <div className={styles.Form}>
            <div>
              <FormField name="text" placeholderId="cardForm.text.placeholder">
                {({ field }) => (
                  <Textarea
                    className={styles.TextField}
                    minRows={1}
                    maxRows={6}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit();
                      }
                      if (isFunction(keyActions?.[e.key])) {
                        e.preventDefault();
                        keyActions?.[e.key]();
                      }
                    }}
                    {...field}
                  />
                )}
              </FormField>
            </div>
            <Button
              htmlType="submit"
              type="primary"
              icon={buttonIcon}
              disabled={isLoading}
              loading={isLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

const CardCreateForm = ({ column, collapsed }) =>
  collapsed ? (
    <div className={styles.CollapsedPlaceholder} />
  ) : (
    <CardForm
      initialValues={CARD_INITIAL_VALUES}
      fetchFn={fetchCreateCard}
      fetchKey="createBoardCard"
      mutateArgs={{ column }}
      buttonIcon={<PlusOutlined />}
    />
  );
const CardEditForm = ({ card, content, onSuccess, onCancel }) => (
  <CardForm
    initialValues={pick(content, ['text'])}
    fetchFn={fetchEditCard}
    fetchKey="editBoardCard"
    mutateArgs={{ cardId: card._id, cardContentId: content._id }}
    onSuccess={onSuccess}
    buttonIcon={<SaveOutlined />}
    keyActions={{ Escape: onCancel }}
  />
);

function useDeleteCard({ card, content }) {
  const { _id: boardId } = useBoardData();
  const arg = { boardId, cardId: card?._id, cardContentId: content?._id };
  const { mutate, isLoading: isDeleting } = useMutation({
    mutationFn: fetchDeleteCard,
    mutationKey: ['deleteBoardCard', arg],
    onSuccess: async data => {
      await queryClient.refetchQueries('board');
    },
  });

  const doDelete = () => {
    showConfirmModal({
      title: <FormattedMessage id="deleteCard.confirm.title" />,
      content: <FormattedMessage id="deleteCard.confirm.content" />,
      onOk: () => mutate(arg),
    });
  };

  return { doDelete, isDeleting };
}

function CardContent({ card, content }) {
  const { ownerDisplayName, ownerPicture, ownerId, text } = content;
  const user = useSelector(getUser);
  const isMy = ownerId === user.id;
  const { working } = useScreenshots();

  const [editing, setEditing] = useState(false);
  const { doDelete, isDeleting } = useDeleteCard({ card, content });
  const showControls = !editing && !isDeleting && !working;

  return (
    <div
      className={classNames(styles['Card__Row'], styles['Card__Row--Content'])}
    >
      <div>
        <Avatar
          size="small"
          {...(ownerPicture
            ? { src: ownerPicture }
            : { icon: <StopOutlined /> })}
        />
      </div>
      <div className="Flex--FullSize">
        <div className={styles['Card__Owner']}>
          {ownerDisplayName || (
            <span className={styles['Card__Owner--Redacted']}>
              <FormattedMessage id="boardCard.ownerDisplayName.redacted" />
            </span>
          )}
        </div>
        <div className={styles['Card__Text']}>
          {editing ? (
            <CardEditForm
              card={card}
              content={content}
              onSuccess={() => setEditing(false)}
              onCancel={() => setEditing(false)}
            />
          ) : (
            <div onDoubleClick={isMy ? () => setEditing(true) : noop}>
              {text}
            </div>
          )}
        </div>
      </div>
      {showControls && (
        <div className={styles['Card__ContentControls']}>
          {isMy && (
            <Tooltip
              title={<FormattedMessage id="boardCard.actions.edit.tooltip" />}
            >
              <EditOutlined
                className={classNames(
                  styles['Card__Control'],
                  styles['Card__Control--Yellow']
                )}
                onClick={() => setEditing(true)}
              />
            </Tooltip>
          )}
          {isMy && (
            <Tooltip
              title={<FormattedMessage id="boardCard.actions.delete.tooltip" />}
            >
              <DeleteOutlined
                onClick={doDelete}
                className={classNames(
                  styles['Card__Control'],
                  styles['Card__Control--Red']
                )}
              />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
}

const fetchCardVote = axiosToQueryFn(({ boardId, cardId, value }) =>
  axios.post(apiRoutes.boardCardVote(boardId, cardId), {
    value,
  })
);

function useCardVote({ cardId }) {
  const { _id: boardId } = useBoardData();
  const { mutate, isLoading } = useMutation({
    mutationFn: fetchCardVote,
    mutationKey: ['boardCardVote', { boardId, cardId }],
    onSuccess: async data => {
      await queryClient.refetchQueries('board');
    },
  });

  const vote = value => mutate({ boardId, cardId, value });

  return { vote, isVoting: isLoading };
}

function VoteButton({ count, vote, isVoting, value, className, icon, myVote }) {
  const { working } = useScreenshots();

  return (
    <Button
      onClick={() => vote(value)}
      size="small"
      icon={icon}
      className={classNames(
        styles['Card__Vote'],
        {
          [styles['Card__Vote--Active']]: !working && myVote === value,
          [styles['Card__Vote--Disabled']]: isVoting,
        },
        className
      )}
    >
      {isNumber(count) && <span>{count}</span>}
    </Button>
  );
}

function HighlightButton({ card, isHighlighted, setHighlightedId }) {
  return isHighlighted ? (
    <Tooltip
      title={<FormattedMessage id="boardCard.actions.unhighlight.tooltip" />}
    >
      <HighlightFilled
        onClick={() => setHighlightedId(undefined)}
        className={classNames(
          styles['Card__Control'],
          styles['Card__Control--Green']
        )}
      />
    </Tooltip>
  ) : (
    <Tooltip
      title={<FormattedMessage id="boardCard.actions.highlight.tooltip" />}
    >
      <HighlightOutlined
        onClick={() => setHighlightedId(card._id)}
        className={classNames(
          styles['Card__Control'],
          styles['Card__Control--Green']
        )}
      />
    </Tooltip>
  );
}

function ShareButton({ card, generateLink }) {
  return (
    <Tooltip title={<FormattedMessage id="boardCard.actions.share.tooltip" />}>
      <ShareAltOutlined
        onClick={() => {
          writeText(generateLink(card._id));
          showSuccessMessage(
            <FormattedMessage id="boardCard.actions.share.success" />
          );
        }}
        className={classNames(
          styles['Card__Control'],
          styles['Card__Control--Magenta']
        )}
      />
    </Tooltip>
  );
}

const fetchMergeCards = axiosToQueryFn(({ boardId, card1Id, card2Id }) =>
  axios.post(apiRoutes.boardCardsMerge(boardId, card1Id, card2Id))
);

function MergeButton({ card }) {
  const { _id: boardId } = useBoardData();
  const { initiatingMergeCardId, setInitiatingMergeCardId } =
    useContext(MergeContext);

  const { mutate, isLoading: isMerging } = useMutation({
    mutationFn: fetchMergeCards,
    mutationKey: [
      'boardCardsMerge',
      { boardId, card1Id: initiatingMergeCardId, card2Id: card._id },
    ],
    onSuccess: async data => {
      await queryClient.refetchQueries('board');
      setInitiatingMergeCardId(null);
    },
  });

  const isInitiating = card._id === initiatingMergeCardId;

  if (!initiatingMergeCardId) {
    return (
      <Tooltip
        title={
          <FormattedMessage id="boardCard.actions.initializeMerge.tooltip" />
        }
      >
        <BranchesOutlined
          onClick={() => setInitiatingMergeCardId(card._id)}
          className={classNames(
            styles['Card__Control'],
            styles['Card__Control--Blue'],
            { [styles['Card__Control--Disabled']]: isMerging }
          )}
        />
      </Tooltip>
    );
  }

  if (isInitiating) {
    return (
      <Tooltip
        title={<FormattedMessage id="boardCard.actions.stopMerge.tooltip" />}
      >
        <NodeExpandOutlined
          onClick={() => setInitiatingMergeCardId(null)}
          className={classNames(
            styles['Card__Control'],
            styles['Card__Control--Blue'],
            { [styles['Card__Control--Disabled']]: isMerging }
          )}
        />
      </Tooltip>
    );
  }

  const finishMerge = () =>
    mutate({ boardId, card1Id: initiatingMergeCardId, card2Id: card._id });
  return (
    <Tooltip
      title={<FormattedMessage id="boardCard.actions.finishMerge.tooltip" />}
    >
      <PullRequestOutlined
        onClick={finishMerge}
        className={classNames(
          styles['Card__Control'],
          styles['Card__Control--Blue'],
          { [styles['Card__Control--Disabled']]: isMerging }
        )}
      />
    </Tooltip>
  );
}

function ListItem({ item: card }) {
  const rights = useBoardSelfRights();
  const { working } = useScreenshots();
  const { highlightedId, setHighlightedId, generateLink } =
    useContext(HighlightContext);
  const isHighlighted = highlightedId === card._id;
  const { vote, isVoting } = useCardVote({ cardId: card._id });

  const fetchUserSettings = async () => {
    const response = await axios.get(apiRoutes.userSettings);
    return response.data.shortcutApiKey;
  };

  const { data: shortcutApiKey } = useQuery('userSettings', fetchUserSettings);

  const showControls = rights?.moderatorRight && !working;

  const ref = useScrollToThis({
    active: isHighlighted,
    skipIfInScrollView: true,
  });

  return (
    <div
      ref={ref}
      className={classNames(styles.Card, {
        [styles['Card--Highlight']]: isHighlighted,
      })}
    >
      {showControls && (
        <div
          className={classNames(
            styles['Card__Row'],
            styles['Card__Row--Votes']
          )}
        >
          <HighlightButton
            card={card}
            isHighlighted={isHighlighted}
            setHighlightedId={setHighlightedId}
          />
          <ShareButton card={card} generateLink={generateLink} />
          {rights?.moderatorRight && <MergeButton card={card} />}
          <TrelloCardButton card={card} />
          {shortcutApiKey && <ShortcutCardButton card={card} />}
        </div>
      )}
      {sortBy(card.contents, 'createdAt').map(content => (
        <CardContent key={content.createdAt} card={card} content={content} />
      ))}
      <div
        className={classNames(styles['Card__Row'], styles['Card__Row--Votes'])}
      >
        <VoteButton
          count={card.votes.down}
          vote={vote}
          isVoting={isVoting}
          value={-1}
          className={styles['Card__Vote--ColorRed']}
          icon={<DislikeOutlined />}
          myVote={card.votes.my}
        />
        <VoteButton
          vote={vote}
          isVoting={isVoting}
          value={0}
          className={styles['Card__Vote--ColorNeutral']}
          icon={<MehOutlined />}
          myVote={card.votes.my}
        />
        <VoteButton
          count={card.votes.up}
          vote={vote}
          isVoting={isVoting}
          value={1}
          className={styles['Card__Vote--ColorGreen']}
          icon={<LikeOutlined />}
          myVote={card.votes.my}
        />
      </div>
    </div>
  );
}

const ListItemAnimationWrapper = forwardRef(function ListItemAnimationWrapper(
  { children },
  outerRef
) {
  const { working } = useScreenshots();
  const debouncedWorking = useDebouncedVariable(working);
  const ownRef = useRef();
  const ref = outerRef || ownRef;
  const lastOffsetTop = useRef();
  const [translate, setTranslate] = useStateIfMounted();

  useEffect(() => {
    if (isNumber(translate)) {
      return;
    }
    if (ref?.current) {
      const prevOffsetTop = lastOffsetTop.current;
      const currentOffsetTop = ref.current.offsetTop;
      if (isNumber(prevOffsetTop) && prevOffsetTop !== currentOffsetTop) {
        setTranslate(prevOffsetTop - currentOffsetTop);
        setTimeout(() => setTranslate(undefined), 300);
      }
      lastOffsetTop.current = currentOffsetTop;
    }
  });

  const isActive = isNumber(translate) && !working && !debouncedWorking;

  return (
    <div
      ref={ref}
      className={classNames(styles.PositionAnimationWrapper, {
        [styles['PositionAnimationWrapper--Active']]: isActive,
      })}
      style={isActive ? { transform: `translateY(${translate}px)` } : {}}
    >
      {children}
    </div>
  );
});

function BoardColumn({ column, cards, collapsed, collapse, expand }) {
  const Icon = BoardColumnsMetadata[column]?.icon || 'div';
  const [initiatingMergeCardId, setInitiatingMergeCardId] = useState();

  return (
    <MergeContext.Provider
      value={{ initiatingMergeCardId, setInitiatingMergeCardId }}
    >
      <div
        className={classNames(styles.Column, {
          [styles['Column--Collapsed']]: collapsed,
        })}
      >
        <div className={styles.ListHeader}>
          <a
            className={styles.ListHeader__Toggle}
            href="#"
            onClick={collapsed ? expand : collapse}
          >
            {collapsed ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
          </a>
          <Icon />
          <FormattedMessage id={`boardColumn.${column}`} />
        </div>
        {!collapsed && (
          <Scrollbars
            style={{ flex: 1 }}
            renderView={props => (
              <div {...props} className={styles['Column__ScrollbarsView']} />
            )}
            autoHide
          >
            <div className={styles.ListContent}>
              <TransitionGroup component={null}>
                {(cards || []).map(item => (
                  <CSSTransition
                    key={item._id}
                    timeout={300}
                    classNames={cssTransitionClassNames('scale-up', {
                      appear: false,
                      enter: true,
                      exit: true,
                    })}
                  >
                    <ListItemAnimationWrapper>
                      <ListItem item={item} />
                    </ListItemAnimationWrapper>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
          </Scrollbars>
        )}
      </div>
    </MergeContext.Provider>
  );
}

export default function BoardColumns() {
  const { type: boardType, cards } = useBoardData();
  const { ref: screenshoRef } = useScreenshots();
  const { sort } = useDataOptionsContext();

  const columns = BoardColumnsByType[boardType] || [];
  const cardsByColumn = mapValues(groupBy(cards, 'column'), val =>
    makeSorter(sort)(val)
  );
  const [collapsed, setCollapsed] = useState(List(columns.map(() => false)));
  const makeCollapsedProps = i => ({
    collapsed: collapsed.get(i),
    collapse: () => setCollapsed(old => old.set(i, true)),
    expand: () => setCollapsed(old => old.set(i, false)),
  });

  return (
    <div className={styles.Container}>
      <div className={styles.FormRow}>
        {columns.map((col, i) => (
          <CardCreateForm key={col} column={col} {...makeCollapsedProps(i)} />
        ))}
      </div>
      <div className={styles.ContentRow} ref={screenshoRef}>
        {columns.map((col, i) => (
          <BoardColumn
            key={col}
            column={col}
            cards={cardsByColumn[col]}
            {...makeCollapsedProps(i)}
          />
        ))}
      </div>
    </div>
  );
}
