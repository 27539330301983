import { Record } from 'immutable';

const InitialState = Record({
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
});
const INITIAL_STATE = InitialState();

const LOGIN = 'AUTH/LOGIN';
const REFRESH_TOKENS = 'AUTH/REFRESH_TOKENS';
const LOGOUT = 'AUTH/LOGOUT';

export function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN:
      return state
        .set('user', action.user)
        .set('accessToken', action.accessToken)
        .set('refreshToken', action.refreshToken);
    case REFRESH_TOKENS:
      return state
        .set('accessToken', action.accessToken)
        .set('refreshToken', action.refreshToken);
    case LOGOUT:
      return state.clear();
    default:
      return state;
  }
}

export const getLoggedIn = state => !!state.auth.accessToken;
export const getUser = state => state.auth.user;
export const getAccessToken = state => state.auth.accessToken;
export const getRefreshToken = state => state.auth.refreshToken;

export function loginUser(user, accessToken, refreshToken) {
  return { type: LOGIN, user, accessToken, refreshToken };
}

export function refreshTokens(accessToken, refreshToken) {
  return { type: REFRESH_TOKENS, accessToken, refreshToken };
}

export function logoutUser() {
  return async (dispatch, getState, context) => {
    context.queryClient.clear();
    dispatch({ type: LOGOUT });
  };
}
