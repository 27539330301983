import { Tooltip } from 'antd';
import axios from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import apiRoutes from '../app/apiRoutes';
import { DEFAULT_REFETCH_INTERVAL } from '../config/constants';
import styles from '../styles/containers/ActivityPanel.module.less';

const fetchActivity = async ({ queryKey: [, { boardId }] }) => {
  const response = await axios.get(apiRoutes.getActivityStats(boardId));
  return response.data;
};

const activityHandlers = {
  participantStats: {
    getAmount: data => data.participantStats.amountOfActiveParticipants,
    getPercentage: data => data.participantStats.percentageOfActiveParticipants,
  },
  voterStats: {
    getAmount: data => data.voterStats.amountOfVotingParticipants,
    getPercentage: data => data.voterStats.percentageOfVotingParticipants,
  },
};

function ActivityPanel({ activityType, activityDescription, action }) {
  const { id: boardId } = useParams();
  const { data, isLoading, isError } = useQuery({
    queryKey: ['activity', { boardId }],
    queryFn: fetchActivity,
    retry: false,
    refetchInterval: DEFAULT_REFETCH_INTERVAL,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading activity</div>;
  }

  const handler = activityHandlers[activityType];

  const amountOfParticipants = handler.getAmount(data);
  const percentageOfParticipants = handler.getPercentage(data);

  return (
    <div className={styles.Panel}>
      <Tooltip
        title={`${activityDescription} participant is one who has ${action} at least one card.`}
      >
        <p>{`${activityDescription} Participants: ${amountOfParticipants}`}</p>
      </Tooltip>
      <Tooltip
        title={`Percentage of ${activityDescription.toLowerCase()} participants calculated from amount of participants.`}
      >
        <p>{`Weighted activity: ${percentageOfParticipants}%`}</p>
      </Tooltip>
    </div>
  );
}

export default ActivityPanel;
