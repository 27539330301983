const routes = {
  defaultLogged: '/',
  defaultNonlogged: '/login',
  root: '/',
  login: '/login',
  loginCallback: '/login/callback',
  createBoard: '/board/create',
  boardDetail: '/board/:id',
  termsOfUse: '/terms',
  privacyPolicy: '/privacy-policy',
  trelloCallback: '/trello/callback',
};

export default routes;
