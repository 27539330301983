import { FormattedMessage } from 'react-intl';

function formatStandardError(e) {
  if (e.code) {
    return <FormattedMessage id={e.code} defaultMessage={e.message} />;
  }
  return e.message;
}

export function formatError(e) {
  if (e instanceof Error) {
    return formatStandardError(e);
  }
  return 'Error';
}

export function makeError({ message, code }) {
  const err = new Error(message);
  err.code = code;
  return err;
}
