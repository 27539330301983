import React from 'react';
import { hydrate, render } from 'react-dom';

import App from './App';
import { loadPolyfills } from './polyfills';
import reportWebVitals from './reportWebVitals';
import './styles/main.less';

async function main() {
  await loadPolyfills();
  const rootElement = document.getElementById('root');
  if (rootElement.hasChildNodes()) {
    hydrate(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      rootElement
    );
  } else {
    render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      rootElement
    );
  }
}
main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
