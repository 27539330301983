import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { getLoggedIn } from '../app/redux/auth';
import routes from '../app/routes';

export default function NoLoginGuard({ children }) {
  const loggedIn = useSelector(getLoggedIn);

  if (loggedIn) {
    return <Navigate to={routes.defaultLogged} replace />;
  }

  return children;
}
