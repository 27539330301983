function makeKey(key) {
  return `Retros/${key}`;
}

export const localStorageAdapter = {
  getItem: key => {
    if (!localStorage) {
      return null;
    }
    const val = localStorage.getItem(makeKey(key));
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  },
  setItem: (key, val) => {
    if (!localStorage) {
      return;
    }
    try {
      localStorage.setItem(makeKey(key), JSON.stringify(val));
    } catch (e) {
      localStorage.setItem(makeKey(key), val);
    }
  },
  removeItem: key => {
    if (!localStorage) {
      return;
    }
    localStorage.removeItem(makeKey(key));
  },
};
