// This factory function is needed in order to prevent webpack from evaluating process.env at build time.
function makeConfig(env) {
  const config = {
    version: env.REACT_APP_VERSION,
    apiUrl: env.REACT_APP_API_URL,
    build: env.BUILD,
  };
  console.log('Config: ', config);
  return config;
}

export const config = makeConfig(process.env);
