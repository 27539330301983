import {
  BranchesOutlined,
  CameraOutlined,
  HighlightOutlined,
  InteractionOutlined,
  LikeOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import apiRoutes from '../app/apiRoutes';
import routes from '../app/routes';
import Header from '../containers/Header';
import styles from '../styles/pages/LoginPage.module.less';
import BasePage from './BasePage';

function PromoCard({ icon, titleId, textId }) {
  return (
    <div className={styles.PromoCard}>
      {icon}
      <Typography.Title level={2}>
        <FormattedMessage id={titleId} />
      </Typography.Title>
      <Typography.Text className={styles.PromoText}>
        <FormattedMessage id={textId} />
      </Typography.Text>
    </div>
  );
}

export default function LoginPage() {
  return (
    <BasePage header={<Header />}>
      <div className={styles.Content}>
        <div className={styles.Form}>
          <Button
            type="primary"
            className={styles.Button}
            href={apiRoutes.loginUrl}
          >
            <FormattedMessage id="login.google" />
          </Button>
          <Typography.Text italic>
            <FormattedMessage
              id="login.agreement"
              values={{
                termsOfUse: (
                  <Link to={routes.termsOfUse}>
                    <FormattedMessage id="login.termsOfUse" />
                  </Link>
                ),
              }}
            />
          </Typography.Text>
        </div>
        <div className={styles.Promo}>
          <PromoCard
            icon={<InteractionOutlined />}
            titleId="promo.trello.title"
            textId="promo.trello.text"
          />
          <PromoCard
            icon={<HighlightOutlined />}
            titleId="promo.highlight.title"
            textId="promo.highlight.text"
          />
          <PromoCard
            icon={<BranchesOutlined />}
            titleId="promo.merge.title"
            textId="promo.merge.text"
          />
          <PromoCard
            icon={<CameraOutlined />}
            titleId="promo.screenshot.title"
            textId="promo.screenshot.text"
          />
          <PromoCard
            icon={<LikeOutlined />}
            titleId="promo.voting.title"
            textId="promo.voting.text"
          />
          <PromoCard
            icon={<UnorderedListOutlined />}
            titleId="promo.dashboard.title"
            textId="promo.dashboard.text"
          />
        </div>
      </div>
    </BasePage>
  );
}
