import { useCallback, useRef, useState } from 'react';

import { useMountEffect } from './hookUtils';

export function useMountedIndicator({ logPrefix } = {}) {
  const mounted = useRef(true);
  useMountEffect(() => {
    mounted.current = true;
    if (logPrefix) {
      console.log(logPrefix, 'mount');
    }
    return () => {
      mounted.current = false;
      if (logPrefix) {
        console.log(logPrefix, 'unmount');
      }
    };
  });
  return mounted;
}

export function useStateIfMounted(initialValue) {
  const mounted = useMountedIndicator();
  const [state, setState] = useState(initialValue);
  const safeSetState = useCallback(
    val => {
      if (mounted.current) {
        setState(val);
      }
    },
    [mounted]
  );

  return [state, safeSetState];
}
