import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import routes from '../app/routes';
import { DEFAULT_REDIRECT_TIMEOUT } from '../config/constants';

export function AutoRedirectText({ timeout = DEFAULT_REDIRECT_TIMEOUT }) {
  return (
    <FormattedMessage
      id="autoRedirect.text"
      values={{ timeout: Math.round(timeout / 1000) }}
    />
  );
}

export function AutoRedirect({
  to = routes.defaultLogged,
  timeout = DEFAULT_REDIRECT_TIMEOUT,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    const timeoutInstance = setTimeout(() => navigate(to), timeout);
    return () => clearTimeout(timeoutInstance);
  }, [navigate, timeout, to]);

  return null;
}
