import { useNavigate } from 'react-router';

import routes from '../app/routes';
import styles from '../styles/components/Logo.module.less';

export default function Logo() {
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate(routes.defaultLogged)} className={styles.Logo}>
      Retros.Instea
    </div>
  );
}
