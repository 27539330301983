import axios from 'axios';

export function imgBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export async function imgUrlToBase64(url) {
  const response = await axios.get(url, {
    responseType: 'blob',
  });
  return imgBlobToBase64(response.data);
}
