import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Drawer, Space, Spin, Tooltip } from 'antd';
import axios from 'axios';
import classNames from 'classnames';
import StatusCodes from 'http-status-codes';
import { noop, omit, some, stubFalse } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

import apiRoutes from '../app/apiRoutes';
import { useBoardData, useBoardSelfRights } from '../app/contexts/BoardContext';
import { useUserSettings } from '../app/hooks/authHooks';
import { useFavorites } from '../app/hooks/favoritesHooks';
import { queryClient } from '../appSingletons';
import { useReduxDialogControls } from '../common/utils/dialogUtils';
import { formatError } from '../common/utils/errorUtils';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import icon from '../resources/img/trello-icon.png';
import styles from '../styles/containers/TrelloCardButton.module.less';
import TrelloCardForm from './TrelloCardForm';

const INITIAL_VALUES = {
  boardId: null,
  listId: null,
  labelIds: [],
  assigneeIds: [],
  title: '',
  description: '',
  includeVoteTally: true,
  includeLink: true,
};

const fetchTrelloCard = axiosToQueryFn(
  ({ queryKey: [, { boardId, cardId }] }) =>
    axios.get(apiRoutes.getTrelloConnectedCard(boardId, cardId)),
  { enableErrorMessage: false }
);

const fetchSaveTrelloCard = axiosToQueryFn(({ boardId, cardId, input }) =>
  axios.put(apiRoutes.saveTrelloConnectedCard(boardId, cardId), input)
);

const fetchUnlinkTrelloCard = axiosToQueryFn(({ boardId, cardId }) =>
  axios.delete(apiRoutes.unlinkTrelloConnectedCard(boardId, cardId))
);

function formatTrelloError(e) {
  if (e.code === StatusCodes.BAD_REQUEST) {
    return <FormattedMessage id="trello.error.badRequest" />;
  }
  if (e.code === StatusCodes.NOT_FOUND) {
    return <FormattedMessage id="trello.error.notFound" />;
  }
  if (e.code === StatusCodes.FORBIDDEN) {
    return <FormattedMessage id="trello.error.forbidden" />;
  }
  return formatError(e);
}

const EDITABLE_FIELDS = [
  'assigneeIds',
  'labelIds',
  'title',
  'description',
  'includeVoteTally',
  'includeLink',
];
const isDisabled = name => !some(EDITABLE_FIELDS, ef => ef === name);

function TrelloCardDrawer({ open, onClose, card }) {
  const { _id: boardId } = useBoardData();
  const {
    data: trelloCard,
    isLoading,
    isFetched,
    isError,
    error,
  } = useQuery({
    queryFn: fetchTrelloCard,
    queryKey: ['connectedTrelloCard', { boardId, cardId: card._id }],
    retry: false,
    enabled: open,
  });
  const { mutate, isLoading: isSaving } = useMutation({
    mutationFn: fetchSaveTrelloCard,
    mutationKey: ['saveConnectedTrelloCard', { boardId, cardId: card._id }],
    onSuccess: () => {
      queryClient.refetchQueries('connectedTrelloCard');
      onClose();
    },
  });
  const { mutate: unlink, isLoading: isUnlinking } = useMutation({
    mutationFn: fetchUnlinkTrelloCard,
    mutationKey: ['unlinkConnectedTrelloCard', { boardId, cardId: card._id }],
    onSuccess: () => {
      queryClient.refetchQueries('connectedTrelloCard');
      onClose();
    },
  });

  const isEdit =
    trelloCard ||
    (isError &&
      (error.code === StatusCodes.NOT_FOUND ||
        error.code === StatusCodes.BAD_REQUEST ||
        error.code === StatusCodes.FORBIDDEN));

  const favorites = useFavorites('trelloCard');

  return (
    <Drawer
      open={open}
      onClose={onClose}
      destroyOnClose
      maskClosable
      title={
        <FormattedMessage
          id={`trello.drawer.${isEdit ? 'edit' : 'create'}.title`}
        />
      }
      width={500}
    >
      <Space direction="vertical" size={10} className="FullWidth">
        {isLoading && <Spin />}
        {isError && (
          <Alert type="error" showIcon message={formatTrelloError(error)} />
        )}
        {isFetched && !isError && (
          <TrelloCardForm
            mutate={input => mutate({ boardId, cardId: card._id, input })}
            disabled={isSaving || isUnlinking}
            isLoading={isSaving}
            initialValues={
              isEdit
                ? omit(trelloCard, ['fieldDescriptors'])
                : { ...INITIAL_VALUES, ...favorites.value }
            }
            fieldDescriptors={trelloCard?.fieldDescriptors}
            isDisabled={isEdit ? isDisabled : stubFalse}
            favorites={favorites}
          />
        )}
        {isFetched && isEdit && (
          <Tooltip
            title={
              <FormattedMessage id="trello.connectedCard.unlink.tooltip" />
            }
          >
            <Button
              onClick={() => unlink({ boardId, cardId: card._id })}
              loading={isUnlinking}
              disabled={isUnlinking || isSaving}
              icon={<DeleteOutlined />}
            >
              <span>
                <FormattedMessage id="trello.connectedCard.unlink" />
              </span>
            </Button>
          </Tooltip>
        )}
      </Space>
    </Drawer>
  );
}

export default function TrelloCardButton({ card }) {
  const { data: settings } = useUserSettings();
  const { moderatorRight } = useBoardSelfRights();
  const enabled = settings?.trelloEnabled;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { open: openSettings } = useReduxDialogControls({ id: 'userSettings' });

  if (!moderatorRight) {
    return null;
  }

  return (
    <>
      <Tooltip
        title={
          enabled ? (
            <FormattedMessage id="trello.button.tooltip" />
          ) : (
            <FormattedMessage
              id="trello.disabled.activationPrompt"
              values={{
                settings: (
                  <a href="#" onClick={openSettings}>
                    <FormattedMessage id="trello.disabled.activationPrompt.settings" />
                  </a>
                ),
              }}
            />
          )
        }
      >
        <img
          className={classNames(styles.IconButton, {
            [styles['IconButton--Disabled']]: !enabled,
          })}
          src={icon}
          onClick={enabled ? () => setDrawerVisible(true) : noop}
          alt=""
        />
      </Tooltip>
      <TrelloCardDrawer
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        card={card}
      />
    </>
  );
}
