import { pick } from 'lodash-es';
import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

import { getUser } from '../redux/auth';

export const BoardContext = createContext({
  name: '',
  participants: [],
  cards: [],
});

export function useBoardData() {
  return useContext(BoardContext);
}

export function useBoardSelfRights() {
  const { participants } = useBoardData();
  const user = useSelector(getUser);
  const self = participants.find(p => p.userId === user.id);
  return self
    ? pick(self, ['ownerRight', 'moderatorRight'])
    : { ownerRight: false, moderatorRight: false };
}
