import { ImportOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { isEqual, values as objValues, pick, some, toPairs } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

export default function TrelloFormFavoritesButton({ value, setValue }) {
  const { values, setFieldValue } = useFormikContext();
  const formValue = pick(values, ['boardId', 'listId', 'templateId']);

  const isFormNonEmpty = some(objValues(formValue));
  const isValueNonEmpty = some(objValues(value));

  return (
    <Row align="middle" justify="end" gutter={10}>
      {!isFormNonEmpty && (
        <Col>
          <Button icon={<StarOutlined />} disabled />
        </Col>
      )}
      {isFormNonEmpty && !isEqual(formValue, value) && (
        <Col>
          <Tooltip
            title={<FormattedMessage id="trello.favorites.save.tooltip" />}
          >
            <Button
              icon={<StarOutlined />}
              onClick={() => setValue(formValue)}
            />
          </Tooltip>
        </Col>
      )}
      {isFormNonEmpty && isEqual(formValue, value) && (
        <Col>
          <Tooltip
            title={<FormattedMessage id="trello.favorites.remove.tooltip" />}
          >
            <Button icon={<StarFilled />} onClick={() => setValue({})} />
          </Tooltip>
        </Col>
      )}
      {isValueNonEmpty && (
        <Col>
          <Tooltip
            title={<FormattedMessage id="trello.favorites.load.tooltip" />}
          >
            <Button
              icon={<ImportOutlined />}
              disabled={isEqual(formValue, value)}
              onClick={() =>
                toPairs(value).map(([name, val]) => setFieldValue(name, val))
              }
            />
          </Tooltip>
        </Col>
      )}
    </Row>
  );
}
