import { Card } from 'antd';
import axios from 'axios';
import { format as formatDate } from 'date-fns';
import { useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { generatePath, useNavigate } from 'react-router';

import apiRoutes from '../app/apiRoutes';
import { BoardType } from '../app/enums/BoardType';
import routes from '../app/routes';
import { useMountAndUpdateEffect } from '../common/utils/hookUtils';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import { showSuccessMessage } from '../common/utils/messageUtils';
import BoardForm from '../containers/BoardForm';
import Header from '../containers/Header';
import styles from '../styles/pages/CreateBoardPage.module.less';
import BasePage from './BasePage';

const INITIAL_VALUES = {
  type: BoardType.RETROSPECTIVE,
  name: '',
};

function DefaultNameSetter() {
  const { setFieldValue, touched, values } = useFormikContext();
  const type = values.type;
  const intl = useIntl();
  const defaultName = `${intl
    .formatMessage({
      id: `boardType.${type}`,
    })
    .replace(/\s\(.+/, '')} [${formatDate(new Date(), 'yyyy-MM-dd')}]`;
  useMountAndUpdateEffect(
    {
      onMount: () => {
        setFieldValue('name', defaultName);
      },
      onUpdate: () => {
        if (!touched.name) {
          setFieldValue('name', defaultName);
        }
      },
    },
    [type]
  );

  return null;
}

const fetchSaveBoard = axiosToQueryFn(values =>
  axios.post(apiRoutes.createBoard, values)
);

export default function CreateBoardPage() {
  const navigate = useNavigate();

  const { mutate: saveBoard, isLoading } = useMutation({
    mutationFn: fetchSaveBoard,
    mutationKey: ['createBoard'],
    onSuccess: data => {
      showSuccessMessage(<FormattedMessage id="createBoard.success" />);
      navigate(generatePath(routes.boardDetail, { id: data._id }));
    },
  });

  return (
    <BasePage
      header={
        <Header
          title={<FormattedMessage id="createBoard.title" />}
          backHref={routes.root}
        />
      }
    >
      <div className="Flex Flex--Center Flex--FullSize">
        <Card className={styles.Card}>
          <BoardForm
            mutate={saveBoard}
            isLoading={isLoading}
            initialValues={INITIAL_VALUES}
          >
            <DefaultNameSetter />
          </BoardForm>
        </Card>
      </div>
    </BasePage>
  );
}
