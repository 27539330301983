import { DeleteOutlined } from '@ant-design/icons';
import { Button, List, Spin } from 'antd';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

import apiRoutes from '../app/apiRoutes';
import { queryClient } from '../appSingletons';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import { showConfirmModal } from '../common/utils/messageUtils';
import { fetchShortcutApiKeys } from '../common/utils/shortcutApiKeysUtils.js';
import styles from '../styles/containers/ShortcutApiKey.module.less';

function ShortcutApiKeyList() {
  const { data: apiKeys, isLoading } = useQuery(
    'shortcutApiKeys',
    fetchShortcutApiKeys
  );

  const fetchDeleteShortcutApiKey = axiosToQueryFn(apiKeyId =>
    axios.delete(apiRoutes.deleteShortcutApiKeys(apiKeyId))
  );

  const { mutate: deleteShortcutApiKey, isLoading: deletingShortcutApiKey } =
    useMutation({
      mutationFn: fetchDeleteShortcutApiKey,
      onSuccess: () => {
        queryClient.invalidateQueries('shortcutApiKeys');
      },
    });

  if (isLoading) {
    return <Spin />;
  }

  return (
    <List
      className={styles.List}
      dataSource={apiKeys}
      renderItem={apiKey => (
        <List.Item
          key={apiKey._id}
          className={styles.List__Item}
          actions={[
            <Button
              type="text"
              icon={<DeleteOutlined className={styles.DeleteButton} />}
              onClick={() =>
                showConfirmModal({
                  title: (
                    <FormattedMessage id="delete.ShortcutApiKey.confirm.title" />
                  ),
                  content: (
                    <FormattedMessage id="delete.ShortcutApiKey.confirm.content" />
                  ),
                  onOk: () => deleteShortcutApiKey(apiKey._id),
                })
              }
              disabled={deletingShortcutApiKey}
            />,
          ]}
        >
          {apiKey.name}
        </List.Item>
      )}
    />
  );
}

export default ShortcutApiKeyList;
