import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getAccessToken, getRefreshToken, getUser } from '../app/redux/auth';
import { localStorageAdapter } from '../common/utils/localStorageUtils';
import { LOCAL_STORAGE_KEY_AUTH } from '../config/constants';

export default function AuthPersistenceSynchronizer() {
  const user = useSelector(getUser);
  const accessToken = useSelector(getAccessToken);
  const refreshToken = useSelector(getRefreshToken);

  useEffect(() => {
    if (
      user &&
      accessToken &&
      refreshToken &&
      !localStorageAdapter.getItem(LOCAL_STORAGE_KEY_AUTH)
    ) {
      localStorageAdapter.setItem(LOCAL_STORAGE_KEY_AUTH, {
        user,
        accessToken,
        refreshToken,
      });
    }
    if (
      (!user || !accessToken || !refreshToken) &&
      localStorageAdapter.getItem(LOCAL_STORAGE_KEY_AUTH)
    ) {
      localStorageAdapter.removeItem(LOCAL_STORAGE_KEY_AUTH);
    }
  }, [user, accessToken, refreshToken]);

  return null;
}
