import { Button, Result } from 'antd';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import routes from '../app/routes';
import { AutoRedirect, AutoRedirectText } from '../components/Redirect';

export default function NotFoundPage({ redirectTo = routes.defaultLogged }) {
  const navigate = useNavigate();
  const go = useCallback(() => {
    navigate(redirectTo);
  }, [navigate, redirectTo]);

  return (
    <div className="FullSize Flex Flex--Center">
      <AutoRedirect />
      <Result
        status="404"
        title="404"
        subTitle={
          <>
            <FormattedMessage id="notFound.message" />
            <AutoRedirectText />
          </>
        }
        extra={
          <Button type="primary" onClick={go}>
            <FormattedMessage id="notFound.button" />
          </Button>
        }
      />
    </div>
  );
}
