import {
  BugOutlined,
  GithubOutlined,
  InfoOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button, Popover } from 'antd';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import routes from '../app/routes';
import { useFullscreen } from '../components/fullscreen';
import { config } from '../config/config';
import {
  DEVELOPED_BY,
  JENKINS,
  REPOSITORY,
  SPONSORED_BY,
} from '../config/constants';
import styles from '../styles/containers/Footer.module.less';

function ScrollButton({ direction, target, enabled }) {
  return enabled ? (
    <Button
      shape="circle"
      icon={direction === 'left' ? <LeftOutlined /> : <RightOutlined />}
      onClick={() => {
        const current = parseFloat(
          target.style.transform?.replace(/[^0-9.-]/g, '')
        );
        const diff = 40 * (direction === 'left' ? 1 : -1);
        const newValue = isNaN(current) ? diff : current + diff;
        const boundedNewValue = Math.min(
          0,
          Math.max(target.clientWidth - target.scrollWidth, newValue)
        );
        target.style.transform = `translateX(${boundedNewValue}px)`;
      }}
    />
  ) : (
    <div />
  );
}

function FooterContent() {
  return (
    <>
      <div className={styles.Column}>
        <Link to={routes.termsOfUse}>
          <FormattedMessage id="footer.termsOfUse" />
        </Link>
      </div>
      <div className={styles.Column}>
        <Link to={routes.privacyPolicy}>
          <FormattedMessage id="footer.privacyPolicy" />
        </Link>
      </div>
      <div className={styles.Column}>
        <FormattedMessage id="footer.developedBy" />{' '}
        <a
          href={`mailto:${DEVELOPED_BY.email}`}
          target="_blank"
          rel="noreferrer"
        >
          {DEVELOPED_BY.name}
        </a>
      </div>
      <div className={styles.Column}>
        <FormattedMessage id="footer.sponsoredBy" />{' '}
        <a href={SPONSORED_BY.website} target="_blank" rel="noreferrer">
          {SPONSORED_BY.name}
        </a>
      </div>
      <div className={styles.Column}>
        <a href={REPOSITORY} target="_blank" rel="noreferrer">
          <GithubOutlined /> Repository
        </a>
      </div>
      <div className={styles.Column}>
        <a href={`${REPOSITORY}/issues`} target="_blank" rel="noreferrer">
          <BugOutlined /> <FormattedMessage id="footer.reportBug" />
        </a>
      </div>
      <div className={styles.Column}>
        <a
          href={`${REPOSITORY}/blob/main/CHANGELOG.md`}
          target="_blank"
          rel="noreferrer"
        >
          V{config.version}
        </a>
      </div>
      <div className={styles.Column}>
        <a href={`${JENKINS}/job/retros/`} target="_blank" rel="noreferrer">
          Build {config.build}
        </a>
      </div>
    </>
  );
}

export default function Footer() {
  const { enabled: fullscreenEnabled } = useFullscreen();
  const [scrollersNeeded, setScrollersNeeded] = useState(false);
  const ref = useRef();
  const cref = useRef();
  const ciref = useRef();
  useEffect(() => {
    const handler = () => {
      const newScrollersNeeded =
        ciref.current?.scrollWidth > ref.current?.clientWidth;
      setScrollersNeeded(newScrollersNeeded);
      if (!scrollersNeeded && newScrollersNeeded) {
        ciref.current.style.transform = 'translateX(0)';
      }
      if (scrollersNeeded && !newScrollersNeeded) {
        ciref.current.style.transform = 'none';
      }
    };
    window.addEventListener('resize', handler);
    handler();
    return () => window.removeEventListener('resize', handler);
  }, [scrollersNeeded]);

  return (
    <>
      <div
        ref={ref}
        className={classNames(styles.Container, 'Hide--SmAndLower')}
        style={{ visibility: fullscreenEnabled ? 'hidden' : 'visible' }}
      >
        <ScrollButton
          direction="left"
          target={ciref.current}
          enabled={scrollersNeeded}
        />
        <div ref={cref} className={styles.Content}>
          <div ref={ciref} className={styles.Content__Inner}>
            <FooterContent />
          </div>
        </div>
        <ScrollButton
          direction="right"
          target={ciref.current}
          enabled={scrollersNeeded}
        />
      </div>
      <Popover
        placement="leftTop"
        content={
          <div className={styles.FooterPopover}>
            <FooterContent />
          </div>
        }
      >
        <Button
          shape="circle"
          icon={<InfoOutlined />}
          className={classNames(styles.FooterTriggerSm, 'Hide--MdAndHigher')}
        />
      </Popover>
    </>
  );
}
