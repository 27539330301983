import { Form as AntForm, Button, Divider, Input } from 'antd';
import axios from 'axios';
import { Form, Formik, useFormikContext } from 'formik';
import { isObject, isString, stubFalse } from 'lodash-es';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';

import apiRoutes from '../app/apiRoutes';
import { useMountAndUpdateEffect } from '../common/utils/hookUtils';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import {
  parseTrelloTemplate,
  trelloCardDescriptionToFields,
  trelloCardFieldsToDescription,
} from '../common/utils/trelloTemplateUtils';
import FormField from '../components/forms/FormField';
import { BasicSwitch } from '../components/forms/booleanFields';
import { FetchingSelect } from '../components/forms/selects';
import { useLocalizedYup } from '../config/intl/LocaleProvider';
import TrelloFormFavoritesButton from './TrelloFormFavoritesButton';

const fetchTrelloBoards = axiosToQueryFn(() =>
  axios.get(apiRoutes.trelloBoards)
);

const fetchTrelloLists = axiosToQueryFn(({ queryKey: [, { boardId }] }) =>
  axios.get(apiRoutes.trelloLists(boardId))
);

const fetchTrelloTemplates = axiosToQueryFn(({ queryKey: [, { boardId }] }) =>
  axios.get(apiRoutes.trelloTemplates(boardId))
);

const fetchTrelloLabels = axiosToQueryFn(({ queryKey: [, { boardId }] }) =>
  axios.get(apiRoutes.trelloLabels(boardId))
);

const fetchTrelloMembers = axiosToQueryFn(({ queryKey: [, { boardId }] }) =>
  axios.get(apiRoutes.trelloMembers(boardId))
);

function TemplateParsedFields({
  templatesRef,
  fieldDescriptors,
  setFieldDescriptors,
  disabled,
}) {
  const { values, setFieldValue } = useFormikContext();
  const templateText = (templatesRef.current || []).find(
    tpl => tpl.id === values.templateId
  )?.desc;

  useMountAndUpdateEffect(
    {
      onMount: () => {
        if (!fieldDescriptors && templateText) {
          setFieldDescriptors(parseTrelloTemplate(templateText));
        }
      },
      onUpdate: () => {
        setFieldDescriptors(
          templateText ? parseTrelloTemplate(templateText) : null
        );
      },
    },
    [templateText]
  );
  useMountAndUpdateEffect(
    {
      onMount: () => {
        if (isString(values.description) && fieldDescriptors) {
          setFieldValue(
            'description',
            trelloCardDescriptionToFields(values.description, fieldDescriptors)
          );
        }
      },
      onUpdate: ([prevFieldDescriptors]) => {
        if (isString(values.description) && fieldDescriptors) {
          setFieldValue(
            'description',
            trelloCardDescriptionToFields(values.description, fieldDescriptors)
          );
        }
        if (isObject(values.description) && !fieldDescriptors) {
          setFieldValue(
            'description',
            trelloCardFieldsToDescription(
              values.description,
              prevFieldDescriptors
            )
          );
        }
      },
    },
    [fieldDescriptors]
  );

  if (!fieldDescriptors) {
    return (
      <FormField
        name="description"
        labelId="trello.description"
        disabled={disabled}
      >
        {({ field, label }) => (
          <AntForm.Item label={label}>
            <TextareaAutosize className="ant-input" {...field} />
          </AntForm.Item>
        )}
      </FormField>
    );
  } else {
    return fieldDescriptors.map(({ fieldName, fieldLabel, placeholder }) => (
      <FormField
        name={`description.${fieldName}`}
        label={fieldLabel}
        disabled={disabled}
        key={fieldName}
      >
        {({ field, label }) => (
          <AntForm.Item label={label}>
            <TextareaAutosize
              className="ant-input"
              {...field}
              placeholder={placeholder}
            />
          </AntForm.Item>
        )}
      </FormField>
    ));
  }
}

export default function TrelloCardForm({
  mutate,
  isLoading,
  disabled,
  initialValues,
  fieldDescriptors: fieldDescriptorsOuter,
  isDisabled = stubFalse,
  favorites,
}) {
  const yup = useLocalizedYup();

  const validationSchema = yup.object().shape({
    boardId: yup.string().required(),
    listId: yup.string().required(),
    labelIds: yup.array(yup.string()),
    assigneeIds: yup.array(yup.string()),
    title: yup.string().required(),
    includeVoteTally: yup.boolean(),
    includeLink: yup.boolean(),
  });

  const templates = useRef();
  const [fieldDescriptors, setFieldDescriptors] = useState(
    fieldDescriptorsOuter
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async values => {
        const { description, ...rest } = values;
        const input = {
          fieldDescriptors,
          ...rest,
          description: isString(description)
            ? description
            : trelloCardFieldsToDescription(description, fieldDescriptors),
        };
        await mutate(input);
      }}
    >
      {({ values }) => (
        <AntForm
          component={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form>
            <FormField
              name="boardId"
              labelId="trello.board"
              disabled={isDisabled('boardId')}
            >
              {({ field, label }) => (
                <AntForm.Item label={label}>
                  <FetchingSelect
                    queryFn={fetchTrelloBoards}
                    queryKey={['trelloBoards']}
                    allowClear
                    {...field}
                  />
                </AntForm.Item>
              )}
            </FormField>
            <FormField
              name="listId"
              labelId="trello.list"
              disabled={isDisabled('listId')}
            >
              {({ field, label }) => (
                <AntForm.Item label={label}>
                  <FetchingSelect
                    queryFn={fetchTrelloLists}
                    queryKey={['trelloLists', { boardId: values.boardId }]}
                    allowClear
                    selectFirstByDefault
                    enabled={!!values.boardId}
                    {...field}
                  />
                </AntForm.Item>
              )}
            </FormField>
            <FormField
              name="templateId"
              labelId="trello.template"
              disabled={isDisabled('templateId')}
            >
              {({ field, label }) => (
                <AntForm.Item label={label}>
                  <FetchingSelect
                    queryFn={fetchTrelloTemplates}
                    queryKey={['trelloTemplates', { boardId: values.boardId }]}
                    allowClear
                    enabled={!!values.boardId}
                    onLoadOptions={(options, data) => {
                      templates.current = data;
                    }}
                    {...field}
                  />
                </AntForm.Item>
              )}
            </FormField>
            <TrelloFormFavoritesButton {...favorites} />
            <Divider type="horizontal" />
            <FormField
              name="labelIds"
              labelId="trello.labels"
              disabled={isDisabled('labelIds')}
            >
              {({ field, label }) => (
                <AntForm.Item label={label}>
                  <FetchingSelect
                    queryFn={fetchTrelloLabels}
                    queryKey={['trelloLabels', { boardId: values.boardId }]}
                    allowClear
                    enabled={!!values.boardId}
                    mode="multiple"
                    {...field}
                  />
                </AntForm.Item>
              )}
            </FormField>
            <FormField
              name="assigneeIds"
              labelId="trello.assignee"
              disabled={isDisabled('assigneeIds')}
            >
              {({ field, label }) => (
                <AntForm.Item label={label}>
                  <FetchingSelect
                    queryFn={fetchTrelloMembers}
                    queryKey={[
                      'fetchTrelloMembers',
                      { boardId: values.boardId },
                    ]}
                    allowClear
                    enabled={!!values.boardId}
                    mode="multiple"
                    {...field}
                  />
                </AntForm.Item>
              )}
            </FormField>
            <FormField
              name="title"
              labelId="trello.title"
              disabled={isDisabled('title')}
            >
              {({ field, label }) => (
                <AntForm.Item label={label}>
                  <Input {...field} />
                </AntForm.Item>
              )}
            </FormField>
            <TemplateParsedFields
              templatesRef={templates}
              fieldDescriptors={fieldDescriptors}
              setFieldDescriptors={setFieldDescriptors}
              disabled={isDisabled('description')}
            />
            <FormField
              name="includeVoteTally"
              labelId="trello.includeVoteTally"
              disabled={isDisabled('includeVoteTally')}
            >
              {({ field, label }) => (
                <AntForm.Item label={label}>
                  <BasicSwitch {...field} />
                </AntForm.Item>
              )}
            </FormField>
            <FormField
              name="includeLink"
              labelId="trello.includeLink"
              disabled={isDisabled('includeLink')}
            >
              {({ field, label }) => (
                <AntForm.Item label={label}>
                  <BasicSwitch {...field} />
                </AntForm.Item>
              )}
            </FormField>
            <div className="Flex Flex--Center">
              <Button
                htmlType="submit"
                type="primary"
                disabled={disabled}
                loading={isLoading}
              >
                <FormattedMessage id="action.submit" />
              </Button>
            </div>
          </Form>
        </AntForm>
      )}
    </Formik>
  );
}
