import { LinkOutlined } from '@ant-design/icons';
import { Form as AntForm, Button, Input } from 'antd';
import { Form, Formik } from 'formik';
import { values as objValues, stubFalse } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';

import { BoardType } from '../app/enums/BoardType';
import FormField from '../components/forms/FormField';
import { BasicSelect } from '../components/forms/selects';
import { useLocalizedYup } from '../config/intl/LocaleProvider';

export default function BoardForm({
  mutate,
  isLoading,
  initialValues,
  isDisabled = stubFalse,
  children,
}) {
  const intl = useIntl();
  const yup = useLocalizedYup();

  const validationSchema = yup.object().shape({
    type: yup.string().required(),
    name: yup.string().required(),
    url: yup.string().url(),
    notes: yup.string(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async values => {
        await mutate(values);
      }}
    >
      <AntForm
        component={false}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form>
          <FormField
            name="type"
            labelId="createBoard.type"
            disabled={isDisabled('type')}
          >
            {({ field, label }) => (
              <AntForm.Item label={label}>
                <BasicSelect
                  {...field}
                  options={objValues(BoardType).map(val => ({
                    value: val,
                    label: intl.formatMessage({
                      id: `boardType.${val}`,
                    }),
                  }))}
                />
              </AntForm.Item>
            )}
          </FormField>
          <FormField
            name="name"
            labelId="createBoard.name"
            disabled={isDisabled('name')}
          >
            {({ field, label }) => (
              <AntForm.Item label={label}>
                <Input {...field} />
              </AntForm.Item>
            )}
          </FormField>
          <FormField
            name="url"
            labelId="createBoard.url"
            disabled={isDisabled('url')}
          >
            {({ field, label }) => (
              <AntForm.Item label={label}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Input {...field} />
                  {field.value && (
                    <Button
                      icon={<LinkOutlined style={{ width: '40px' }} />}
                      style={{ marginLeft: '10px', width: '45px' }}
                      onClick={() => window.open(field.value, '_blank')}
                    />
                  )}
                </div>
              </AntForm.Item>
            )}
          </FormField>
          <FormField
            name="notes"
            labelId="createBoard.notes"
            disabled={isDisabled('notes')}
          >
            {({ field, label }) => (
              <AntForm.Item label={label}>
                <Input.TextArea
                  {...field}
                  maxLength={1024}
                  autoSize={{ minRows: 3 }}
                />
              </AntForm.Item>
            )}
          </FormField>
          {children}
          <div className="Flex Flex--Center">
            <Button
              htmlType="submit"
              type="primary"
              disabled={isLoading}
              loading={isLoading}
            >
              <FormattedMessage id="action.submit" />
            </Button>
          </div>
        </Form>
      </AntForm>
    </Formik>
  );
}
