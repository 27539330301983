import { DownOutlined, MoreOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Menu,
  PageHeader,
  Popover,
  Row,
  Tooltip,
} from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { getUser, logoutUser } from '../app/redux/auth';
import { useReduxDialogControls } from '../common/utils/dialogUtils';
import Logo from '../components/Logo';
import { useFullscreen } from '../components/fullscreen';
import styles from '../styles/containers/Header.module.less';
import UserSettingsDrawer from './UserSettingsDrawer';

function UserDropdownContentExtra() {
  const { isOpen: settingsOpen, close: closeSettings } = useReduxDialogControls(
    {
      id: 'userSettings',
    }
  );

  return (
    <>
      <UserSettingsDrawer open={settingsOpen} onClose={closeSettings} />
    </>
  );
}

function UserDropdownContent() {
  const dispatch = useDispatch();
  const { open: openSettings } = useReduxDialogControls({
    id: 'userSettings',
  });

  return (
    <Menu
      items={[
        {
          key: 'settings',
          onClick: openSettings,
          label: (
            <a href="#">
              <FormattedMessage id="userDropdown.settings" />
            </a>
          ),
        },
        {
          key: 'logout',
          onClick: () => dispatch(logoutUser()),
          label: (
            <a href="#">
              <FormattedMessage id="userDropdown.logout" />
            </a>
          ),
        },
      ]}
    />
  );
}

function UserLabel({ className, iconRight, ...rest }) {
  const user = useSelector(getUser);
  if (!user) {
    return null;
  }

  return (
    <div className={classNames(styles.UserLabel, className)} {...rest}>
      <Avatar src={user.picture} shape="circle" />
      <span className={styles.UserLabel__Text}>
        {user.displayName || user.email}
      </span>
      {iconRight}
    </div>
  );
}

function HeaderExtraAndSubtitle({ className, subTitle }) {
  return (
    <Popover
      placement="bottomLeft"
      content={
        <div className={styles.SmallPopover}>
          <UserLabel />
          <Divider type="horizontal" className={styles['Divider--Sm']} />
          <UserDropdownContent />
          <UserDropdownContentExtra />
          {subTitle && (
            <>
              <Divider type="horizontal" className={styles['Divider--Lg']} />
              {subTitle}
            </>
          )}
        </div>
      }
    >
      <Button shape="circle" icon={<MoreOutlined />} className={className} />
    </Popover>
  );
}

function HeaderExtra({ subTitle }) {
  const user = useSelector(getUser);
  if (!user) {
    return null;
  }

  return (
    <>
      <Dropdown
        trigger="click"
        overlay={<UserDropdownContent />}
        overlayClassName={styles.UserDropdown}
      >
        <UserLabel iconRight={<DownOutlined />} className="Hide--MdAndLower" />
      </Dropdown>
      <UserDropdownContentExtra />
      <HeaderExtraAndSubtitle
        subTitle={subTitle}
        className="Hide--LgAndHigher"
      />
    </>
  );
}

export default function Header({ title, subTitle, backHref }) {
  const { enabled: fullscreenEnabled } = useFullscreen();
  const navigate = useNavigate();

  return (
    !fullscreenEnabled && (
      <div className={styles.Container}>
        <PageHeader
          title={
            <Row align="middle" className={styles.TitleRow} wrap={false}>
              <Col className={styles.Logo}>
                <Logo />
              </Col>
              <Col className={styles.Title}>
                <Tooltip title={title}>
                  <span>{title}</span>
                </Tooltip>
              </Col>
            </Row>
          }
          subTitle={
            <>
              {subTitle && <div className={styles.Subtitle}>{subTitle}</div>}
              <HeaderExtraAndSubtitle
                subTitle={subTitle}
                className="Hide--MdAndHigher"
              />
            </>
          }
          extra={<HeaderExtra subTitle={subTitle} />}
          onBack={backHref ? () => navigate(backHref) : undefined}
        />
      </div>
    )
  );
}
