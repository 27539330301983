import { ExportOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import axios from 'axios';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { generatePath } from 'react-router';
import URI from 'urijs';

import apiRoutes from '../app/apiRoutes';
import { useBoardData } from '../app/contexts/BoardContext';
import { useUserSettings } from '../app/hooks/authHooks';
import routes from '../app/routes';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import { imgBlobToBase64 } from '../common/utils/imgUtils';
import { useScreenshots } from '../components/screenshots';
import LoadingPage from '../pages/LoadingPage';

const fetchTrelloOutput = axiosToQueryFn(boardId =>
  axios.get(apiRoutes.getTrelloOutput(boardId))
);

function SummaryReportModal({ open, onClose, data, screenshot }) {
  const { _id: id } = useBoardData();
  const link = URI()
    .path(generatePath(routes.boardDetail, { id }))
    .query('')
    .hash('')
    .toString();

  return (
    <Modal
      title={<FormattedMessage id="summaryReport.modal.title" />}
      open={open}
      onCancel={onClose}
      destroyOnClose
      width={1000}
    >
      <Space direction="vertical" size={30}>
        <div>
          <h1>
            <FormattedMessage id="summaryReport.modal.retroBoard" />
          </h1>
          <div>
            <a href={link}>
              <FormattedMessage id="summaryReport.modal.link" />
            </a>
          </div>
          <img src={screenshot} alt="" style={{ maxWidth: 800 }} />
        </div>
        {data && (
          <div>
            <h1>
              <FormattedMessage id="summaryReport.modal.actionItems" />
            </h1>
            <ReactMarkdown>{data}</ReactMarkdown>
          </div>
        )}
      </Space>
    </Modal>
  );
}

export default function SummaryReportButton() {
  const { produce } = useScreenshots();
  const { data: settings } = useUserSettings();
  const { _id: boardId } = useBoardData();

  const [modalData, setModalData] = useState();
  const [loading, setLoading] = useState(false);

  const prepareDataAndOpen = async () => {
    setLoading(true);
    try {
      const dataPromise = settings?.trelloEnabled
        ? fetchTrelloOutput(boardId).catch(e => null)
        : Promise.resolve(null);
      const screenshotPromise = Promise.resolve()
        .then(produce)
        .then(imgBlobToBase64);

      const [data, screenshot] = await Promise.all([
        dataPromise,
        screenshotPromise,
      ]);
      setModalData({ data, screenshot });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={prepareDataAndOpen} icon={<ExportOutlined />}>
        <span>
          <FormattedMessage id="summaryReport.label" />
        </span>
      </Button>
      <SummaryReportModal
        open={!!modalData}
        onClose={() => setModalData(false)}
        {...(modalData || {})}
      />
      {loading && (
        <LoadingPage
          titleId="summaryReport.loading"
          className="BgWhite ZIndex-1"
        />
      )}
    </>
  );
}
