export const LOCAL_STORAGE_KEY_AUTH = 'Auth/UserAndToken';
export const LOCAL_STORAGE_KEY_REDIRECT_AFTER_LOGIN = 'Tmp/RedirectAfterLogin';
export const LOCAL_STORAGE_KEY_FAVORITES = 'Favorites/';

export const DEFAULT_REDIRECT_TIMEOUT = 5000;
export const DEFAULT_REFETCH_INTERVAL = 3000;

export const DEVELOPED_BY = {
  name: 'Juraj Matuš',
  email: 'juraj.matus@instea.sk',
};

export const REPOSITORY = 'https://github.com/instea/retros';

export const JENKINS = 'https://jenkins2.instea.co';

export const SPONSORED_BY = {
  name: 'Instea',
  website: 'https://instea.sk',
};
