import {
  BulbOutlined,
  CarryOutOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  CommentOutlined,
  DeleteOutlined,
  FrownOutlined,
  InfoCircleOutlined,
  LikeOutlined,
  MehOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  RiseOutlined,
  SearchOutlined,
  SmileOutlined,
} from '@ant-design/icons';

export const BoardColumn = {
  DROP: 'DROP',
  ADD: 'ADD',
  KEEP: 'KEEP',
  IMPROVE: 'IMPROVE',
  OBSERVATION: 'OBSERVATION',
  IDEA: 'IDEA',
  SAD: 'SAD',
  MAD: 'MAD',
  GLAD: 'GLAD',
  LIKED: 'LIKED',
  LEARNED: 'LEARNED',
  LACKED: 'LACKED',
  LONGER_FOR: 'LONGED_FOR',
  COMMENT: 'COMMENT',
  QUESTION: 'QUESTION',
  SUGGESTION: 'SUGGESTION',
  WIND: 'WIND',
  ANCHOR: 'ANCHOR',
  ROCKS: 'ROCKS',
  SUN: 'SUN',
  GOAL: 'GOAL',
  HOLD: 'HOLD',
  ASSESS: 'ASSESS',
  TRIAL: 'TRIAL',
  ADOPT: 'ADOPT',
};

export const BoardColumnsMetadata = {
  [BoardColumn.DROP]: {
    icon: DeleteOutlined,
  },
  [BoardColumn.ADD]: {
    icon: PlusCircleOutlined,
  },
  [BoardColumn.KEEP]: {
    icon: CheckCircleOutlined,
  },
  [BoardColumn.IMPROVE]: {
    icon: RiseOutlined,
  },
  [BoardColumn.OBSERVATION]: {
    icon: InfoCircleOutlined,
  },
  [BoardColumn.IDEA]: {
    icon: CommentOutlined,
  },
  [BoardColumn.MAD]: {
    icon: FrownOutlined,
  },
  [BoardColumn.SAD]: {
    icon: MehOutlined,
  },
  [BoardColumn.GLAD]: {
    icon: SmileOutlined,
  },
  [BoardColumn.LIKED]: {
    icon: LikeOutlined,
  },
  [BoardColumn.LEARNED]: {
    icon: BulbOutlined,
  },
  [BoardColumn.LACKED]: {
    icon: PlusCircleOutlined,
  },
  [BoardColumn.LONGER_FOR]: {
    icon: SearchOutlined,
  },
  [BoardColumn.COMMENT]: {
    icon: CommentOutlined,
  },
  [BoardColumn.QUESTION]: {
    icon: QuestionCircleOutlined,
  },
  [BoardColumn.SUGGESTION]: {
    icon: PlusCircleOutlined,
  },
  [BoardColumn.WIND]: {
    icon: PlusCircleOutlined,
  },
  [BoardColumn.ANCHOR]: {
    icon: PlusCircleOutlined,
  },
  [BoardColumn.ROCKS]: {
    icon: PlusCircleOutlined,
  },
  [BoardColumn.SUN]: {
    icon: PlusCircleOutlined,
  },
  [BoardColumn.GOAL]: {
    icon: PlusCircleOutlined,
  },
  [BoardColumn.HOLD]: {
    icon: CloseOutlined,
  },
  [BoardColumn.ASSESS]: {
    icon: ReadOutlined,
  },
  [BoardColumn.TRIAL]: {
    icon: BulbOutlined,
  },
  [BoardColumn.ADOPT]: {
    icon: CarryOutOutlined,
  },
};
