import { SettingOutlined } from '@ant-design/icons';
import { Button, Drawer, Tooltip } from 'antd';
import axios from 'axios';
import { some } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';

import apiRoutes from '../app/apiRoutes';
import { queryClient } from '../appSingletons';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import { showSuccessMessage } from '../common/utils/messageUtils';
import BoardForm from './BoardForm';

const fetchSaveBoard = axiosToQueryFn(({ boardId, values }) =>
  axios.put(apiRoutes.editBoard(boardId), values)
);

const EDITABLE_FIELDS = ['name', 'url', 'notes'];
const isDisabled = name => !some(EDITABLE_FIELDS, ef => ef === name);

export default function BoardSettingsButton({ data, ...rest }) {
  const { mutate: saveBoard, isLoading } = useMutation({
    mutationFn: fetchSaveBoard,
    mutationKey: ['editBoard'],
    onSuccess: data => {
      showSuccessMessage(<FormattedMessage id="boardSettings.success" />);
      setVisible(false);
      queryClient.refetchQueries('board');
    },
  });
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Tooltip title={<FormattedMessage id="boardSettings.label" />}>
        <Button
          icon={<SettingOutlined />}
          onClick={() => setVisible(true)}
          {...rest}
        >
          <span>
            <FormattedMessage id="boardSettings.label" />
          </span>
        </Button>
      </Tooltip>
      <Drawer
        open={visible}
        onClose={() => setVisible(false)}
        destroyOnClose
        title={<FormattedMessage id="boardSettings.title" />}
        width={350}
      >
        <BoardForm
          mutate={values => saveBoard({ boardId: data._id, values })}
          isLoading={isLoading}
          initialValues={data}
          isDisabled={isDisabled}
        />
      </Drawer>
    </>
  );
}
