import { Button, Drawer, Input, Spin, Typography } from 'antd';
import { Form as AntForm } from 'antd';
import axios from 'axios';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import URI from 'urijs';
import * as yup from 'yup';

import apiRoutes from '../app/apiRoutes';
import { queryClient } from '../appSingletons';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../common/utils/messageUtils';
import FormField from '../components/forms/FormField';
import ShortcutApiKeyList from './ShortcutApiKeyList';

const validationSchema = yup.object().shape({
  apiKeyName: yup.string().required(),
  apiKey: yup.string().required(),
});

function useUpdateUserSetting(apiRoute) {
  const { mutate, isLoading } = useMutation({
    mutationFn: axiosToQueryFn(data => axios.put(apiRoute, { apiKey: data })),
    onSuccess: () => {
      console.log('Successfully updated API key');
      showSuccessMessage('API key updated successfully!');
      queryClient.invalidateQueries('shortcutApiKeys');
    },
    onError: error => {
      console.error('An error occurred:', error);
      showErrorMessage('An error occurred while updating the API key.');
    },
  });

  return { mutate, isLoading };
}

function Content({ userSettings }) {
  const { pathname } = useLocation();
  const { mutate: setTrelloToken, isLoading: trelloLoading } =
    useUpdateUserSetting(apiRoutes.setTrelloToken);
  const { mutate: setShortcutKey, isLoading: shortcutLoading } =
    useUpdateUserSetting(apiRoutes.shortcutApiKeys);

  return (
    <>
      <Typography.Title level={3}>
        <FormattedMessage id="userSettings.trello.title" />
      </Typography.Title>
      {userSettings?.trelloEnabled ? (
        <Button
          onClick={() => setTrelloToken()}
          disabled={trelloLoading}
          loading={trelloLoading}
        >
          <FormattedMessage id="userSettings.trello.disconnect" />
        </Button>
      ) : (
        <Button
          href={URI(apiRoutes.trelloAuthUrl)
            .query({ redirect: pathname })
            .toString()}
        >
          <FormattedMessage id="userSettings.trello.connect" />
        </Button>
      )}
      <Typography.Title level={3} style={{ marginTop: '20px' }}>
        <FormattedMessage id="userSettings.shortcut.title" />
      </Typography.Title>
      <Formik
        initialValues={{ apiKeyName: '', apiKey: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const newApiKey = {
            name: values.apiKeyName,
            token: values.apiKey,
          };
          setShortcutKey(newApiKey);
          setSubmitting(false);
          resetForm();
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <AntForm onFinish={handleSubmit}>
            <FormField
              name="apiKeyName"
              placeholderId="userSettings.shortcut.apiKey.name"
            >
              {({ field, label }) => (
                <AntForm.Item label={label}>
                  <Input {...field} />
                </AntForm.Item>
              )}
            </FormField>
            <FormField
              name="apiKey"
              placeholderId="userSettings.shortcut.apiKey"
            >
              {({ field, label }) => (
                <AntForm.Item label={label}>
                  <Input {...field} />
                </AntForm.Item>
              )}
            </FormField>
            <AntForm.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={shortcutLoading}
                disabled={isSubmitting}
              >
                <FormattedMessage id="userSettings.shortcut.saveApiKey" />
              </Button>
            </AntForm.Item>
          </AntForm>
        )}
      </Formik>
      <Typography.Title level={3}>
        <FormattedMessage id="userSettings.shortcut.apiKeyList.title" />
      </Typography.Title>
      <ShortcutApiKeyList />
    </>
  );
}

export default function UserSettingsDrawer({ open, onClose }) {
  const fetchUserSettings = async () => {
    const response = await axios.get(apiRoutes.userSettings);
    const userSettings = response.data;
    userSettings.shortcut = userSettings.shortcut || {};
    userSettings.shortcut.apiKeys = userSettings.shortcut.apiKeys || [];
    return userSettings;
  };

  const {
    data: userSettings,
    isLoading,
    isError,
  } = useQuery('userSettings', fetchUserSettings);

  if (isError) {
    return <div>Error loading user settings</div>;
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      destroyOnClose
      maskClosable
      title={<FormattedMessage id="userSettings.title" />}
      width={350}
    >
      {isLoading && <Spin />}
      {userSettings && <Content userSettings={userSettings} />}
    </Drawer>
  );
}
