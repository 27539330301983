import { BoardColumn } from './BoardColumn';

export const BoardType = {
  RETROSPECTIVE: 'RETROSPECTIVE',
  RETROSPECTIVE_F: 'RETROSPECTIVE_F',
  RETROSPECTIVE_L: 'RETROSPECTIVE_L',
  BRAINSTORMING: 'BRAINSTORMING',
  DISCUSSION: 'DISCUSSION',
  RETROSPECTIVE_JOURNEY: 'RETROSPECTIVE_JOURNEY',
  TECH_RADAR: 'TECH_RADAR',
};

export const BoardColumnsByType = {
  [BoardType.RETROSPECTIVE]: [
    BoardColumn.DROP,
    BoardColumn.ADD,
    BoardColumn.KEEP,
    BoardColumn.IMPROVE,
    BoardColumn.OBSERVATION,
  ],
  [BoardType.RETROSPECTIVE_F]: [
    BoardColumn.MAD,
    BoardColumn.SAD,
    BoardColumn.GLAD,
  ],
  [BoardType.RETROSPECTIVE_L]: [
    BoardColumn.LIKED,
    BoardColumn.LEARNED,
    BoardColumn.LACKED,
    BoardColumn.LONGER_FOR,
  ],
  [BoardType.BRAINSTORMING]: [BoardColumn.IDEA],
  [BoardType.DISCUSSION]: [
    BoardColumn.QUESTION,
    BoardColumn.COMMENT,
    BoardColumn.SUGGESTION,
  ],
  [BoardType.RETROSPECTIVE_JOURNEY]: [
    BoardColumn.WIND,
    BoardColumn.ANCHOR,
    BoardColumn.ROCKS,
    BoardColumn.SUN,
    BoardColumn.GOAL,
  ],
  [BoardType.TECH_RADAR]: [
    BoardColumn.HOLD,
    BoardColumn.ASSESS,
    BoardColumn.TRIAL,
    BoardColumn.ADOPT,
  ],
};
