import { noop } from 'lodash-es';
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router';
import URI from 'urijs';

import { useMountEffect } from '../../common/utils/hookUtils';

export const HighlightContext = createContext({
  highlightedId: null,
  setHighlightedId: noop,
  generateLink: noop,
});

export function RouteHighlightContextProvider({ children, queryParamName }) {
  const [highlightedId, setHighlightedId] = useState();
  const navigate = useNavigate();
  const uri = URI();

  const generateLink = newHighlightedId =>
    uri
      .clone()
      .addQuery({ [queryParamName]: newHighlightedId })
      .toString();

  useMountEffect(() => {
    const initialValue = uri.query(true)[queryParamName];
    if (initialValue) {
      setHighlightedId(initialValue);
      const newUri = uri.clone().removeSearch(queryParamName);
      navigate(newUri.pathname() + newUri.search());
    }
  });

  return (
    <HighlightContext.Provider
      value={{ highlightedId, setHighlightedId, generateLink }}
    >
      {children}
    </HighlightContext.Provider>
  );
}
