import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import axios from 'axios';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router';

import apiRoutes from '../app/apiRoutes';
import { getUser } from '../app/redux/auth';
import routes from '../app/routes';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import { DEFAULT_REFETCH_INTERVAL } from '../config/constants';
import DeleteBoardButton from '../containers/DeleteBoardButton';
import Header from '../containers/Header';
import LeaveBoardButton from '../containers/LeaveBoardButton';
import styles from '../styles/pages/DashboardPage.module.less';
import BasePage from './BasePage';

const fetchBoardsList = axiosToQueryFn(() => axios.get(apiRoutes.boardsList));

export default function DashboardPage() {
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const { data } = useQuery({
    queryKey: ['boards'],
    queryFn: fetchBoardsList,
    refetchInterval: DEFAULT_REFETCH_INTERVAL,
  });

  return (
    <BasePage
      header={<Header title={<FormattedMessage id="dashboard.title" />} />}
    >
      <div className={styles.Content}>
        <Button
          className={styles.Button}
          onClick={() => navigate(routes.createBoard)}
          type="primary"
          icon={<PlusCircleFilled />}
        >
          <span>
            <FormattedMessage id="dashboard.addBoard" />
          </span>
        </Button>
        {(data || []).map(board => (
          <div key={board._id} className={styles.Card}>
            <div>
              <Typography.Title level={4}>
                <FormattedMessage id="dashboard.card.title" />: {board.name}
              </Typography.Title>
              <Typography.Text>
                <FormattedMessage id="dashboard.card.createdAt" />:{' '}
                <FormattedDate value={new Date(board.createdAt)} />{' '}
                <FormattedTime value={new Date(board.createdAt)} />
              </Typography.Text>
              <Button
                type="primary"
                onClick={() =>
                  navigate(generatePath(routes.boardDetail, { id: board._id }))
                }
              >
                <FormattedMessage id="dashboard.card.open" />
              </Button>
              <LeaveBoardButton
                boardId={board._id}
                isOwner={board.ownerId === user.id}
              />
              <DeleteBoardButton
                boardId={board._id}
                isOwner={board.ownerId === user.id}
              />
            </div>
          </div>
        ))}
      </div>
    </BasePage>
  );
}
