import { isEqual } from 'date-fns';
import { isFunction, noop } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = func => useEffect(func, []);

export function useMountAndUpdateEffect(
  { onMount = noop, onUpdate = noop } = {},
  deps
) {
  const wasFired = useRef(false);
  const prevDeps = useRef(deps);
  useEffect(() => {
    let destroy;
    if (wasFired.current) {
      destroy = onUpdate(prevDeps.current);
    } else {
      wasFired.current = true;
      destroy = onMount();
    }
    prevDeps.current = deps;
    return isFunction(destroy) ? destroy : noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useCachedVariable(propValue) {
  const [lastValue, setLastValue] = useState(propValue);
  useEffect(() => {
    if (!isEqual(propValue, lastValue)) {
      setLastValue(propValue);
    }
  }, [lastValue, propValue]);
  return lastValue;
}

const IS_IDENTICAL = (val1, val2) => val1 === val2;
export function useDebouncedVariable(
  value,
  { delay = 500, comparator = IS_IDENTICAL } = {}
) {
  const [debouncedProp, setDebouncedProp] = useState(value);
  const prevValue = useRef(value);
  const timeout = useRef();
  useEffect(() => {
    if (!comparator(value, prevValue.current)) {
      prevValue.current = value;
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => setDebouncedProp(value), delay);
      return () => clearTimeout(timeout.current);
    }
    return noop;
  }, [comparator, delay, value]);

  return debouncedProp;
}
