import { Alert } from 'antd';
import { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: undefined };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    return hasError ? (
      <Alert type="error" showIcon message={error.message} />
    ) : (
      children
    );
  }
}
