import { LeftOutlined, TeamOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Layout, Row } from 'antd';
import Tooltip from 'antd/es/tooltip';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import apiRoutes from '../app/apiRoutes';
import { BoardContext } from '../app/contexts/BoardContext';
import { DataOptionsContextProvider } from '../app/contexts/DataOptionsContext';
import { RouteHighlightContextProvider } from '../app/contexts/HighlightContext';
import { getUser } from '../app/redux/auth';
import routes from '../app/routes';
import { useMountEffect } from '../common/utils/hookUtils';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import { useFullscreen } from '../components/fullscreen';
import { DEFAULT_REFETCH_INTERVAL } from '../config/constants';
import BoardColumns from '../containers/BoardColumns';
import BoardControls from '../containers/BoardControls';
import BoardSettingsButton from '../containers/BoardSettingsButton';
import DeleteBoardButton from '../containers/DeleteBoardButton';
import Header from '../containers/Header';
import LeaveBoardButton from '../containers/LeaveBoardButton';
import ParticipantSummarySection from '../containers/ParticipantSummarySection';
import styles from '../styles/pages/BoardDetailPage.module.less';
import BasePage from './BasePage';
import LoadingPage from './LoadingPage';
import NotFoundPage from './NotFoundPage';

const fetchBoard = axiosToQueryFn(({ queryKey: [, { id }] }) =>
  axios.get(apiRoutes.getBoard(id))
);

const fetchJoinBoard = axiosToQueryFn(({ boardId, user }) =>
  axios.post(apiRoutes.joinBoard(boardId), {
    displayName: user.displayName,
    picture: user.picture,
  })
);

function useBoardData({ id }) {
  const user = useSelector(getUser);
  const joinMutation = useMutation({
    mutationFn: fetchJoinBoard,
    mutationKey: ['joinBoard', { id }],
  });
  useMountEffect(() => {
    joinMutation.mutate({ boardId: id, user });
  });
  const joined =
    joinMutation.data && !joinMutation.isLoading && !joinMutation.error;

  const boardQuery = useQuery({
    queryKey: ['board', { id }],
    queryFn: fetchBoard,
    retry: false,
    enabled: !!joined,
    refetchInterval: DEFAULT_REFETCH_INTERVAL,
  });

  return {
    data: boardQuery.data,
    isLoading: boardQuery.isLoading || joinMutation.isLoading || !joined,
    isError: boardQuery.isError || joinMutation.isError,
    error: joinMutation.error || boardQuery.error,
  };
}

export default function BoardDetailPage() {
  const { id } = useParams();
  const { data, isError, error, isLoading } = useBoardData({ id });
  const [leftColVisible, setLeftColVisible] = useState(true);
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const { enabled: fullscreenEnabled } = useFullscreen();

  if (!data && isLoading) {
    return <LoadingPage titleId="board.loading" />;
  }
  if (isError && error.code === StatusCodes.NOT_FOUND) {
    return <NotFoundPage />;
  }
  if (isError && !data) {
    return <Alert type="error" showIcon message={error.message} />;
  }

  const { name, ownerId } = data;
  const isOwner = ownerId === user.id;

  return (
    <BoardContext.Provider value={data}>
      <RouteHighlightContextProvider queryParamName="activeCard">
        <DataOptionsContextProvider>
          <BasePage
            header={
              <Header
                title={
                  <span>
                    <FormattedMessage id="board.title" />: {name}
                  </span>
                }
                subTitle={
                  <Row gutter={[8, 8]}>
                    <Col>
                      <LeaveBoardButton boardId={id} isOwner={isOwner} />
                    </Col>
                    <Col>
                      <DeleteBoardButton
                        boardId={id}
                        isOwner={isOwner}
                        onSuccess={() => navigate(routes.defaultLogged)}
                      />
                    </Col>
                    <Col>
                      <BoardSettingsButton data={data} />
                    </Col>
                  </Row>
                }
                backHref={routes.root}
              />
            }
          >
            <Layout className={styles.Content}>
              {!fullscreenEnabled && (
                <Layout.Sider
                  className="Hide--MdAndLower"
                  collapsible
                  collapsed={!leftColVisible}
                  onCollapse={val => setLeftColVisible(!val)}
                  collapsedWidth={0}
                  theme="light"
                  width={260}
                  trigger={
                    leftColVisible ? (
                      <Tooltip
                        title={
                          <FormattedMessage id="board.participants.hide" />
                        }
                      >
                        <Button
                          shape="circle"
                          className={styles.ParticipantsIcon}
                          icon={<LeftOutlined />}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={
                          <FormattedMessage id="board.participants.show" />
                        }
                      >
                        <Button
                          shape="circle"
                          className={styles.ParticipantsIcon}
                          icon={<TeamOutlined />}
                        />
                      </Tooltip>
                    )
                  }
                  zeroWidthTriggerStyle={{
                    top: '40vh',
                    backgroundColor: 'transparent',
                    ...(leftColVisible ? { right: 3 } : { left: '100%' }),
                  }}
                >
                  <ParticipantSummarySection />
                </Layout.Sider>
              )}
              <Layout.Content className={styles['Content--Right']}>
                <BoardControls boardId={id} board={data} />
                <div className={styles.ColumnsBlock}>
                  <BoardColumns />
                </div>
              </Layout.Content>
            </Layout>
          </BasePage>
        </DataOptionsContextProvider>
      </RouteHighlightContextProvider>
    </BoardContext.Provider>
  );
}
