import { Select } from 'antd';
import { useFormikContext } from 'formik';
import { deburr, isEmpty, isFunction } from 'lodash-es';
import { useQuery } from 'react-query';

import { useMountAndUpdateEffect } from '../../common/utils/hookUtils';

export function BasicSelect({ name, ...rest }) {
  const { setFieldValue } = useFormikContext();
  return (
    <Select name={name} {...rest} onChange={val => setFieldValue(name, val)} />
  );
}

const DEFAULT_OPTION_MAPPER = ({ id, name }) => ({ value: id, label: name });
export function FetchingSelect({
  queryFn,
  queryKey,
  optionMapper = DEFAULT_OPTION_MAPPER,
  enabled = true,
  name,
  disabled,
  selectFirstByDefault,
  showSearch = true,
  onLoadOptions,
  ...rest
}) {
  const { setFieldValue } = useFormikContext();
  const { data, isLoading } = useQuery({ queryFn, queryKey, enabled });
  const options = (data || []).map(optionMapper);

  useMountAndUpdateEffect(
    {
      onMount: () => {
        if (selectFirstByDefault && !disabled && !isEmpty(options)) {
          setFieldValue(name, options[0].value);
        }
        if (data && isFunction(onLoadOptions)) {
          onLoadOptions(options, data);
        }
      },
      onUpdate: () => {
        if (selectFirstByDefault && !disabled && !isEmpty(options)) {
          setFieldValue(name, options[0].value);
        }
        if (isFunction(onLoadOptions)) {
          onLoadOptions(options, data);
        }
      },
    },
    [data]
  );

  return (
    <BasicSelect
      showSearch={showSearch}
      filterOption={(input, option) => {
        const optText = deburr(option.label.toLowerCase());
        const inputText = deburr(input.toLowerCase());
        return optText.indexOf(inputText) > -1;
      }}
      {...rest}
      name={name}
      loading={isLoading}
      options={options}
      disabled={disabled || !enabled}
    />
  );
}
