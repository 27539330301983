import { Alert } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import URI from 'urijs';

import { loginUser } from '../app/redux/auth';
import routes from '../app/routes';
import { useMountEffect } from '../common/utils/hookUtils';
import { imgUrlToBase64 } from '../common/utils/imgUtils';
import LoadingPage from './LoadingPage';

export default function LoginCallbackPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, accessToken, refreshToken, ...user } = URI().query(true);

  useMountEffect(() => {
    const run = async () => {
      if (accessToken) {
        // Load Base64 image to prevent triggering rate limit on too many downloads
        try {
          const picture = await imgUrlToBase64(user.picture);
          dispatch(loginUser({ ...user, picture }, accessToken, refreshToken));
        } catch (e) {
          dispatch(loginUser(user, accessToken, refreshToken));
        }
        navigate(routes.defaultLogged, { replace: true });
      }
    };
    run();
  });

  if (error) {
    return <Alert type="error" showIcon message={error} />;
  }

  return <LoadingPage titleId="login.loading" />;
}
