import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { Avatar, Button, List, Tag, Tooltip } from 'antd';
import axios from 'axios';
import classNames from 'classnames';
import { every, values } from 'lodash-es';
import Scrollbars from 'react-custom-scrollbars';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import apiRoutes from '../app/apiRoutes';
import { useBoardData, useBoardSelfRights } from '../app/contexts/BoardContext';
import { getUser } from '../app/redux/auth';
import { queryClient } from '../appSingletons';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import styles from '../styles/containers/BoardParticipantsList.module.less';

const fetchChangeBoardParticipantRights = axiosToQueryFn(
  ({ boardId, userId, payload }) =>
    axios.post(apiRoutes.setBoardParticipantRights(boardId, userId), payload)
);

function ChangeRightButton({ textId, payload, userId }) {
  const { _id: boardId } = useBoardData();

  const { mutate, isLoading } = useMutation({
    mutationFn: fetchChangeBoardParticipantRights,
    mutationKey: ['changeBoardParticipantRights', { boardId, userId }],
    onSuccess: () => queryClient.refetchQueries('board'),
  });

  return (
    <Button
      icon={
        every(values(payload), val => !!val) ? (
          <PlusCircleFilled />
        ) : (
          <MinusCircleFilled />
        )
      }
      size="small"
      onClick={() => mutate({ boardId, userId, payload })}
      disabled={isLoading}
      loading={isLoading}
    >
      <span>
        <FormattedMessage id={textId} />
      </span>
    </Button>
  );
}

function ListItemDescription({ item, isSelf }) {
  const rights = useBoardSelfRights();

  const tags = [];

  if (item.ownerRight) {
    tags.push(
      <Tag key="ownerTag" color="green">
        <FormattedMessage id="board.participants.right.owner" />
      </Tag>
    );
  }

  if (item.moderatorRight) {
    if (isSelf || !rights?.ownerRight) {
      tags.push(
        <Tooltip
          key="moderatorTag"
          title={
            <FormattedMessage id="board.participants.right.moderator.tooltip" />
          }
        >
          <Tag color="red">
            <FormattedMessage id="board.participants.right.moderator" />
          </Tag>
        </Tooltip>
      );
    } else {
      tags.push(
        <ChangeRightButton
          key="moderatorRemove"
          textId="board.participants.right.moderator"
          payload={{ moderatorRight: false }}
          userId={item.userId}
        />
      );
    }
  } else {
    if (rights?.ownerRight) {
      tags.push(
        <ChangeRightButton
          key="moderatorAdd"
          textId="board.participants.right.moderator"
          payload={{ moderatorRight: true }}
          userId={item.userId}
        />
      );
    }
  }

  return tags;
}

function ListItem({ item }) {
  const user = useSelector(getUser);
  const isSelf = user.id === item.userId;

  return (
    <List.Item>
      <List.Item.Meta
        avatar={<Avatar src={item.picture} />}
        title={
          <div
            className={classNames(styles.UserLabel, {
              [styles['UserLabel--Self']]: isSelf,
            })}
          >
            {item.displayName}
          </div>
        }
        description={<ListItemDescription item={item} isSelf={isSelf} />}
      />
    </List.Item>
  );
}

export default function BoardParticipantsList() {
  const { participants } = useBoardData();

  return (
    <Scrollbars
      style={{ flex: 1 }}
      renderView={props => (
        <div {...props} className={styles['ScrollbarsView']} />
      )}
      autoHide
    >
      <List
        dataSource={participants}
        header={<FormattedMessage id="board.participants.title" />}
        bordered
        renderItem={item => <ListItem item={item} />}
        className={styles.List}
      />
    </Scrollbars>
  );
}
