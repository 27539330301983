import axios from 'axios';

import apiRoutes from '../../app/apiRoutes';
import { axiosToQueryFn } from './httpUtils';

const fetchUserSettings = axiosToQueryFn(() =>
  axios.get(apiRoutes.userSettings)
);

export const fetchShortcutApiKeys = async () => {
  const response = await fetchUserSettings();
  return response.shortcutApiKey;
};
