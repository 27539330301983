import {
  CameraOutlined,
  CloseOutlined,
  ExportOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PushpinOutlined,
  ShareAltOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Select } from 'antd';
import axios from 'axios';
import classNames from 'classnames';
import { writeText } from 'clipboard-polyfill/text';
import FileSaver from 'file-saver';
import { find, isEqual, last, snakeCase } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import { generatePath } from 'react-router';
import URI from 'urijs';

import apiRoutes from '../app/apiRoutes';
import { useBoardData } from '../app/contexts/BoardContext';
import { useDataOptionsContext } from '../app/contexts/DataOptionsContext';
import routes from '../app/routes';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import { showSuccessMessage } from '../common/utils/messageUtils';
import { useFullscreen } from '../components/fullscreen';
import { useScreenshots } from '../components/screenshots';
import styles from '../styles/pages/BoardDetailPage.module.less';
import ParticipantSummarySection from './ParticipantSummarySection';
import SummaryReportButton from './SummaryReportButton';

function InviteButton() {
  const { _id: id } = useBoardData();
  return (
    <Button
      icon={<ShareAltOutlined />}
      onClick={() => {
        writeText(
          URI()
            .path(generatePath(routes.boardDetail, { id }))
            .query('')
            .hash('')
            .toString()
        );
        showSuccessMessage(<FormattedMessage id="inviteToBoard.success" />);
      }}
    >
      <span>
        <FormattedMessage id="action.invite" />
      </span>
    </Button>
  );
}

function ScreenshotButton() {
  const { produceAndDownload, working } = useScreenshots();
  const { name } = useBoardData();

  return (
    <Button
      icon={<CameraOutlined />}
      onClick={() => produceAndDownload({ name })}
      disabled={working}
      loading={working}
    >
      <span>
        <FormattedMessage id="action.makeScreenshot" />
      </span>
    </Button>
  );
}

function FullscreenButton() {
  const { available, enabled, enable, disable } = useFullscreen();

  if (!available) {
    return null;
  }
  return enabled ? (
    <Button onClick={disable} icon={<FullscreenExitOutlined />}>
      <span>
        <FormattedMessage id="action.nonFullscreen" />
      </span>
    </Button>
  ) : (
    <Button onClick={enable} icon={<FullscreenOutlined />}>
      <span>
        <FormattedMessage id="action.fullscreen" />
      </span>
    </Button>
  );
}

const fetchExportCards = axiosToQueryFn(({ boardId, mime }) =>
  axios.get(apiRoutes.exportBoardCards(boardId), {
    headers: { Accept: mime },
    responseType: 'blob',
  })
);

function ExportButton({ boardId, board }) {
  const { mutate: exportCards, isLoading } = useMutation({
    mutationFn: fetchExportCards,
    mutationKey: ['exportBoardCards'],
    onSuccess: (data, input) => {
      FileSaver.saveAs(
        data,
        `export-${snakeCase(board.name)}.${last(input.mime.split(/\//))}`
      );
    },
  });

  return (
    <Select
      value={{
        value: null,
        label: (
          <div
            className="Flex--Center Flex--Gap5"
            style={{ display: 'inline-flex' }}
          >
            <span className="Hide--SmAndLower">
              <FormattedMessage id="export.label" />
            </span>
            <span>
              <ExportOutlined />
            </span>
          </div>
        ),
      }}
      onChange={val => exportCards({ boardId, mime: val })}
      options={[
        { value: 'application/json', label: 'json' },
        { value: 'text/csv', label: 'csv' },
      ]}
      disabled={isLoading}
      loading={isLoading}
    />
  );
}

const SORT_OPTIONS = [
  {
    key: 'votes.desc',
    value: {
      by: card => card.votes.up - card.votes.down,
      direction: 'desc',
    },
    label: <FormattedMessage id="card.sort.votes" />,
  },
  {
    key: 'createdAt.asc',
    value: { by: 'createdAt', direction: 'asc' },
    label: <FormattedMessage id="card.sort.createdAt" />,
  },
  {
    key: 'createdAt.desc',
    value: { by: 'createdAt', direction: 'desc' },
    label: <FormattedMessage id="card.sort.createdAt" />,
  },
  {
    key: 'creatorName.asc',
    value: { by: 'contents.0.ownerDisplayName', direction: 'asc' },
    label: <FormattedMessage id="card.sort.creatorName" />,
  },
  {
    key: 'creatorName.desc',
    value: { by: 'contents.0.ownerDisplayName', direction: 'desc' },
    label: <FormattedMessage id="card.sort.creatorName" />,
  },
];

function SortSelect() {
  const { sort, setSort } = useDataOptionsContext();
  const sortKey = find(SORT_OPTIONS, opt => isEqual(opt.value, sort))?.key;

  return (
    <Select
      value={sortKey}
      onChange={val =>
        setSort(find(SORT_OPTIONS, opt => opt.key === val)?.value)
      }
      placement="bottomRight"
      dropdownStyle={{ minWidth: 160 }}
    >
      {SORT_OPTIONS.map(({ key, value, label }) => (
        <Select.Option key={key} value={key}>
          <span className={styles.Select__LabelText}>{label}</span>{' '}
          {value.direction === 'asc' ? (
            <SortAscendingOutlined />
          ) : (
            <SortDescendingOutlined />
          )}
        </Select.Option>
      ))}
    </Select>
  );
}

export default function BoardControls({ boardId, board }) {
  const [participantsOpen, setParticipantsOpen] = useState(false);
  const [forceShowControls, setForceShowControls] = useState(false);

  return (
    <>
      <div
        className={classNames(styles.ControlsBlock, {
          'Hide--SmAndLower': !forceShowControls,
        })}
      >
        <InviteButton />
        <ScreenshotButton />
        <FullscreenButton />
        <SummaryReportButton />
        <ExportButton boardId={boardId} board={board} />
        <SortSelect />
        <Button
          className="Hide--LgAndHigher"
          icon={<TeamOutlined />}
          onClick={() => setParticipantsOpen(true)}
        />
        <Button
          className="Hide--MdAndHigher"
          icon={<CloseOutlined />}
          onClick={() => setForceShowControls(false)}
        />
      </div>
      {!forceShowControls && (
        <div className={classNames(styles.ControlsToggle, 'Hide--MdAndHigher')}>
          <Button
            icon={<PushpinOutlined />}
            onClick={() => setForceShowControls(true)}
          />
        </div>
      )}
      <Drawer
        open={participantsOpen}
        onClose={() => setParticipantsOpen(false)}
      >
        <ParticipantSummarySection />
      </Drawer>
    </>
  );
}
