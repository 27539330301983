import { Route, Routes } from 'react-router';

import LoginGuard from '../guards/LoginGuard';
import NoLoginGuard from '../guards/NoLoginGuard';
import BoardDetailPage from '../pages/BoardDetailPage';
import CreateBoardPage from '../pages/CreateBoardPage';
import DashboardPage from '../pages/DashboardPage';
import LoginCallbackPage from '../pages/LoginCallbackPage';
import LoginPage from '../pages/LoginPage';
import NotFoundPage from '../pages/NotFoundPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import TermsOfUsePage from '../pages/TermsOfUsePage';
import TrelloConnectCallbackPage from '../pages/TrelloConnectCallbackPage';
import routes from './routes';

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path={routes.login}
        exact
        element={
          <NoLoginGuard>
            <LoginPage />
          </NoLoginGuard>
        }
      />
      <Route
        path={routes.loginCallback}
        exact
        element={
          <NoLoginGuard>
            <LoginCallbackPage />
          </NoLoginGuard>
        }
      />
      <Route
        path={routes.root}
        exact
        element={
          <LoginGuard>
            <DashboardPage />
          </LoginGuard>
        }
      />
      <Route
        path={routes.createBoard}
        exact
        element={
          <LoginGuard>
            <CreateBoardPage />
          </LoginGuard>
        }
      />
      <Route
        path={routes.boardDetail}
        exact
        element={
          <LoginGuard>
            <BoardDetailPage />
          </LoginGuard>
        }
      />
      <Route path={routes.termsOfUse} exact element={<TermsOfUsePage />} />
      <Route
        path={routes.privacyPolicy}
        exact
        element={<PrivacyPolicyPage />}
      />
      <Route
        path={routes.trelloCallback}
        exact
        element={
          <LoginGuard>
            <TrelloConnectCallbackPage />
          </LoginGuard>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
