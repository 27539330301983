import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, message } from 'antd';
import { isFunction } from 'formik';
import { debounce } from 'lodash-es';
import { useIntl } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';

import { store } from '../../appSingletons';
import LocaleProvider from '../../config/intl/LocaleProvider';

function IntlInjector({ children }) {
  const intl = useIntl();
  return isFunction(children) ? children({ intl }) : children;
}

function MessageWrapper({ children }) {
  return (
    <ReduxProvider store={store}>
      <LocaleProvider skipYupInit>
        <IntlInjector>{children}</IntlInjector>
      </LocaleProvider>
    </ReduxProvider>
  );
}

export function showErrorMessage(content, opts) {
  message.error(<MessageWrapper>{content}</MessageWrapper>, opts);
}

export function showSuccessMessage(content, opts) {
  message.success(<MessageWrapper>{content}</MessageWrapper>, opts);
}

export function showConfirmModal({ title, content, ...rest }) {
  Modal.confirm({
    title: <MessageWrapper>{title}</MessageWrapper>,
    content: <MessageWrapper>{content}</MessageWrapper>,
    icon: <ExclamationCircleOutlined />,
    ...rest,
  });
}

export const showDebouncedErrorMessage = debounce(showErrorMessage, 500);
