import { ConfigProvider as AntdProvider } from 'antd';
import { useContext } from 'react';
import { useState } from 'react';
import { createContext } from 'react';
import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import * as yup from 'yup';

import { getLanguage } from '../../app/redux/settings';
import { store } from '../../appSingletons';
import { getLanguageData } from './intl';

const LocalizedYupContext = createContext(yup);

export function useLocalizedYup() {
  return useContext(LocalizedYupContext);
}

export default function LocaleProvider({ children, skipYupInit }) {
  const [yupCounter, setYupCounter] = useState(0);
  const { locale, messages, antLocale, yupLocale } = getLanguageData(
    getLanguage(store.getState())
  );
  useEffect(() => {
    if (!skipYupInit) {
      yup.setLocale(yupLocale);
      setYupCounter(i => i + 1);
    }
  }, [skipYupInit, yupLocale]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <AntdProvider locale={antLocale}>
        <LocalizedYupContext.Provider value={yup} key={yupCounter}>
          {children}
        </LocalizedYupContext.Provider>
      </AntdProvider>
    </IntlProvider>
  );
}
