import { StatusCodes } from 'http-status-codes';
import { isObject } from 'lodash-es';

import { formatError, makeError } from './errorUtils';
import { showErrorMessage } from './messageUtils';

export function axiosToQueryFn(callAxios, { enableErrorMessage = true } = {}) {
  return async (...args) => {
    const { data, status, statusText } = await callAxios(...args);
    if (status !== StatusCodes.OK) {
      const err = isObject(data)
        ? makeError(data)
        : makeError({ message: statusText, code: status });
      if (enableErrorMessage && status !== StatusCodes.UNAUTHORIZED) {
        showErrorMessage(formatError(err));
      }
      throw err;
    }
    return data;
  };
}
