import axios from 'axios';
import StatusCodes from 'http-status-codes';
import { merge, pickBy } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { QueryClient } from 'react-query';

import { makeStore } from './app/app';
import {
  getAccessToken,
  getRefreshToken,
  logoutUser,
  refreshTokens,
} from './app/redux/auth';
import { showErrorMessage } from './common/utils/messageUtils';

export const queryClient = new QueryClient();
export const store = makeStore(queryClient);

axios.interceptors.request.use(request => {
  const accessToken = getAccessToken(store.getState());
  const refreshToken = getRefreshToken(store.getState());
  merge(request, {
    headers: pickBy(
      {
        'X-AccessToken': accessToken,
        'X-RefreshToken': refreshToken,
      },
      v => !!v
    ),
  });
  return request;
});

axios.interceptors.response.use(
  response => {
    const newAccessToken = response.headers['x-accesstoken'];
    const newRefreshToken = response.headers['x-refreshtoken'];
    if (newAccessToken) {
      store.dispatch(refreshTokens(newAccessToken, newRefreshToken));
    }
    return response;
  },
  error => {
    if (error.response.status === StatusCodes.UNAUTHORIZED) {
      showErrorMessage(<FormattedMessage id="auth.autoLogout" />);
      store.dispatch(logoutUser());
    }
    return error.response;
  }
);
