import React from 'react';

import ActivityPanel from './ActivityPanel';
import BoardParticipantsList from './BoardParticipantsList';

function ParticipantSummarySection() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <BoardParticipantsList style={{ flex: 3 }} />
      <ActivityPanel
        activityType="participantStats"
        activityDescription="Active"
        action="created"
        style={{ flex: 1 }}
      />
      <ActivityPanel
        activityType="voterStats"
        activityDescription="Voting"
        action="voted on"
        style={{ flex: 1 }}
      />
    </div>
  );
}

export default ParticipantSummarySection;
