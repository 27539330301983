import { Drawer, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

import { fetchShortcutApiKeys } from '../common/utils/shortcutApiKeysUtils';
import shortcutIcon from '../resources/img/shortcut-icon.png';
import styles from '../styles/containers/TrelloCardButton.module.less';
import ShortcutStoryForm from './ShortcutCardForm';

export default function ShortcutCardButton({ card }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [upVotes, setUpVotes] = useState(null);
  const [downVotes, setDownVotes] = useState(null);

  const handleImageClick = () => {
    setUpVotes(card.votes.up);
    setDownVotes(card.votes.down);
    setIsModalVisible(true);
  };

  const { data: shortcutApiKeys } = useQuery(
    'shortcutApiKeys',
    fetchShortcutApiKeys
  );

  return (
    <>
      {shortcutApiKeys && shortcutApiKeys.length > 0 && (
        <Tooltip title={<FormattedMessage id="shortcut.button.tooltip" />}>
          <img
            className={classNames(styles.IconButton)}
            src={shortcutIcon}
            alt="Shortcut"
            onClick={handleImageClick}
          />
        </Tooltip>
      )}
      <Drawer
        title={<FormattedMessage id="shortcut.drawer.create.story.title" />}
        placement="right"
        onClose={() => setIsModalVisible(false)}
        open={isModalVisible}
      >
        <ShortcutStoryForm
          initialValues={{
            title: card.contents[0].text,
            description: '',
            includeVoteTally: false,
            includeLink: true,
            shortcutApiKey: '',
            memberId: '',
            workflowId: '',
          }}
          upVotes={upVotes}
          downVotes={downVotes}
        />
      </Drawer>
    </>
  );
}
