import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { getLoggedIn } from '../app/redux/auth';
import routes from '../app/routes';
import { localStorageAdapter } from '../common/utils/localStorageUtils';
import { LOCAL_STORAGE_KEY_REDIRECT_AFTER_LOGIN } from '../config/constants';

export default function LoginGuard({ children }) {
  const loggedIn = useSelector(getLoggedIn);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn) {
      localStorageAdapter.setItem(
        LOCAL_STORAGE_KEY_REDIRECT_AFTER_LOGIN,
        pathname
      );
    } else {
      const redirectTo = localStorageAdapter.getItem(
        LOCAL_STORAGE_KEY_REDIRECT_AFTER_LOGIN
      );
      if (redirectTo) {
        navigate(redirectTo);
        localStorageAdapter.removeItem(LOCAL_STORAGE_KEY_REDIRECT_AFTER_LOGIN);
      }
    }
  }, [loggedIn, navigate, pathname]);

  if (!loggedIn) {
    return <Navigate to={routes.defaultNonlogged} replace />;
  }

  return children;
}
