import URI from 'urijs';

import { config } from '../config/config';

const apiRoutes = {
  loginUrl: URI(config.apiUrl).path('/auth/login/google').toString(),
  trelloAuthUrl: URI(config.apiUrl)
    .path('/plugins/trello/authorize')
    .toString(),
  userSettings: '/settings/user',
  createBoard: '/boards',
  boardsList: '/boards',
  getBoard: id => `/boards/${id}`,
  joinBoard: id => `/boards/${id}/join`,
  leaveBoard: id => `/boards/${id}/leave`,
  editBoard: id => `/boards/${id}`,
  deleteBoard: id => `/boards/${id}`,
  setBoardParticipantRights: (boardId, userId) =>
    `/boards/${boardId}/participants/${userId}/rights`,
  createBoardCard: boardId => `/boards/${boardId}/cards`,
  boardCardVote: (boardId, cardId) => `/boards/${boardId}/cards/${cardId}/vote`,
  boardCardsMerge: (boardId, card1Id, card2Id) =>
    `/boards/${boardId}/cards/merge/${card1Id}/${card2Id}`,
  editBoardCardContent: (boardId, cardId, cardContentId) =>
    `/boards/${boardId}/cards/${cardId}/${cardContentId}`,
  deleteBoardCardContent: (boardId, cardId, cardContentId) =>
    `/boards/${boardId}/cards/${cardId}/${cardContentId}`,
  setTrelloToken: '/plugins/trello/token',
  trelloBoards: '/plugins/trello/api/boards',
  trelloLists: boardId => `/plugins/trello/api/boards/${boardId}/lists`,
  trelloLabels: boardId => `/plugins/trello/api/boards/${boardId}/labels`,
  trelloMembers: boardId => `/plugins/trello/api/boards/${boardId}/members`,
  trelloTemplates: boardId => `/plugins/trello/api/boards/${boardId}/templates`,
  getTrelloConnectedCard: (boardId, cardId) =>
    `/boards/${boardId}/cards/${cardId}/trello-card`,
  saveTrelloConnectedCard: (boardId, cardId) =>
    `/boards/${boardId}/cards/${cardId}/trello-card`,
  unlinkTrelloConnectedCard: (boardId, cardId) =>
    `/boards/${boardId}/cards/${cardId}/trello-card`,
  getTrelloOutput: boardId => `/boards/${boardId}/trello-output`,
  exportBoardCards: boardId => `/boards/${boardId}/cards/export`,
  getActivityStats: id => `/boards/${id}/activity-stats`,
  shortcutApiKeys: '/settings/api-keys',
  deleteShortcutApiKeys: id => `/settings/api-keys/${id}`,
  getShortcutMembers: '/shortcut/members',
  postShortcutStory: '/shortcut/stories',
  getShortcutWorkflows: '/shortcut/workflows',
  getShortcutWorkflowDetails: '/shortcut/workflows/:id',
};

export default apiRoutes;
