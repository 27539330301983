import { Switch } from 'antd';
import { useFormikContext } from 'formik';

export function BasicSwitch({ name, value, onChange, ...rest }) {
  const { values, setFieldValue } = useFormikContext();
  return (
    <Switch
      name={name}
      {...rest}
      checked={!!values[name]}
      onChange={val => setFieldValue(name, val)}
    />
  );
}
