import { useCallback, useState } from 'react';

import { localStorageAdapter } from '../../common/utils/localStorageUtils';
import { LOCAL_STORAGE_KEY_FAVORITES } from '../../config/constants';

function getKey(name) {
  return [LOCAL_STORAGE_KEY_FAVORITES, name].join('/');
}

export function useFavorites(name) {
  const [value, setRawValue] = useState(
    localStorageAdapter.getItem(getKey(name))
  );
  const setValue = useCallback(
    val => {
      setRawValue(val);
      localStorageAdapter.setItem(getKey(name), val);
    },
    [name]
  );

  return { value, setValue };
}
