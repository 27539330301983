import { Typography } from 'antd';
import { ErrorMessage, Field } from 'formik';
import { isFunction } from 'lodash-es';
import { useIntl } from 'react-intl';

export default function FormField({
  name,
  component,
  placeholder: placeholderOuter,
  placeholderId,
  label: labelOuter,
  labelId,
  disabled,
  children,
  ...rest
}) {
  const intl = useIntl();
  const placeholder = placeholderId
    ? intl.formatMessage({ id: placeholderId })
    : placeholderOuter;
  const label = labelId ? intl.formatMessage({ id: labelId }) : labelOuter;

  return (
    <>
      <Field
        name={name}
        component={component}
        placeholder={
          placeholderId
            ? intl.formatMessage({ id: placeholderId })
            : placeholder
        }
        {...rest}
      >
        {isFunction(children)
          ? ({ field, form, meta }) =>
              children({
                field: {
                  ...field,
                  disabled: form.isSubmitting || disabled,
                  placeholder,
                },
                form,
                meta,
                label,
              })
          : undefined}
      </Field>
      <ErrorMessage
        name={name}
        render={msg => <Typography.Text type="danger">{msg}</Typography.Text>}
      />
    </>
  );
}
