import { noop } from 'lodash-es';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  closeDialog,
  getDialogData,
  getIsDialogOpen,
  openDialog,
  setDialogData,
} from '../../app/redux/dialogs';
import { useCachedVariable } from './hookUtils';

export function useReduxDialogControls({ id, spec, onOpenChange = noop }) {
  const dispatch = useDispatch();

  const cachedSpec = useCachedVariable(spec);

  const isOpen = useSelector(getIsDialogOpen(id, cachedSpec));
  const open = useCallback(
    data => {
      dispatch(openDialog({ open: true, id, spec: cachedSpec, data }));
      onOpenChange(true);
    },
    [cachedSpec, dispatch, id, onOpenChange]
  );
  const close = useCallback(() => {
    dispatch(closeDialog({ open: false, id, spec: cachedSpec }));
    onOpenChange(false);
  }, [cachedSpec, dispatch, id, onOpenChange]);

  const data = useSelector(getDialogData(id, cachedSpec));
  const setData = useCallback(
    newData => dispatch(setDialogData({ id, spec: cachedSpec, data: newData })),
    [dispatch, id, cachedSpec]
  );

  return { isOpen, open, close, data, setData };
}
