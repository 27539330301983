import { Spin } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export default function LoadingPage({ title, titleId, className }) {
  return (
    <div className={classNames('FullSize Flex Flex--Center', className)}>
      <Spin
        size="large"
        tip={titleId ? <FormattedMessage id={titleId} /> : title}
      />
    </div>
  );
}
