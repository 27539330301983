import { noop } from 'lodash-es';
import { useContext } from 'react';
import { createContext, useCallback, useRef, useState } from 'react';

import { formatError, makeError } from '../common/utils/errorUtils';
import { showErrorMessage } from '../common/utils/messageUtils';
import { getScrollYDeepChild } from '../common/utils/scrollUtils';
import LoadingPage from '../pages/LoadingPage';

const ScreenshotContext = createContext({
  working: false,
  ref: { current: null },
  produce: noop,
  produceAndDownload: noop,
});

async function makeScreenshot({ ref }) {
  await new Promise(resolve => requestAnimationFrame(resolve));
  if (!ref.current) {
    throw makeError({
      message: 'No screenshot target',
      code: 'screenshot.error.noTarget',
    });
  }

  try {
    const height = Math.max(
      ...[
        ref.current.scrollHeight,
        ...[].map.call(
          ref.current.children,
          ch => getScrollYDeepChild(ch)?.scrollHeight || ch.scrollHeight
        ),
      ]
    );
    ref.current.style.setProperty('width', `${ref.current.scrollWidth}px`);
    ref.current.style.setProperty('min-height', `${height}px`);
    await new Promise(resolve => requestAnimationFrame(resolve));

    const html2canvas = await require('html2canvas');
    const canvas = await html2canvas(ref.current);
    const blob = await new Promise(resolve => canvas.toBlob(resolve));
    return blob;
  } finally {
    ref.current.style.removeProperty('width');
    ref.current.style.removeProperty('min-height');
  }
}

async function downloadReport({ name, ref }) {
  try {
    const FileSaver = await require('file-saver');
    const screenshot = await makeScreenshot({ ref });
    FileSaver.saveAs(screenshot, `${name}.png`);
  } catch (e) {
    showErrorMessage(formatError(e));
  }
}

export function ScreenshotProvider({ children }) {
  const [working, setWorking] = useState(false);
  const ref = useRef();

  const produce = useCallback(async () => {
    setWorking(true);
    try {
      return await makeScreenshot({ ref });
    } finally {
      setWorking(false);
    }
  }, []);

  const produceAndDownload = useCallback(async ({ name }) => {
    setWorking(true);
    try {
      await downloadReport({ name, ref });
    } finally {
      setWorking(false);
    }
  }, []);

  return (
    <ScreenshotContext.Provider
      value={{ working, ref, produce, produceAndDownload }}
    >
      {children}
      {working && (
        <LoadingPage titleId="screenshot.working" className="BgWhite" />
      )}
    </ScreenshotContext.Provider>
  );
}

export function useScreenshots() {
  return useContext(ScreenshotContext);
}
