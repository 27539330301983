import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Markdown from 'react-markdown';
import { useSelector } from 'react-redux';

import { getLanguage } from '../app/redux/settings';
import routes from '../app/routes';
import { useMountEffect } from '../common/utils/hookUtils';
import { getLanguageData } from '../config/intl/intl';
import Header from '../containers/Header';
import styles from '../styles/pages/PolicyPage.module.less';
import BasePage from './BasePage';
import LoadingPage from './LoadingPage';

export default function TermsOfUsePage() {
  const { loadTermsOfUse } = getLanguageData(useSelector(getLanguage));
  const [data, setData] = useState(false);
  useMountEffect(() => {
    loadTermsOfUse().then(md => setData(md));
  });

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <BasePage
      header={
        <Header
          title={<FormattedMessage id="termsOfUse.title" />}
          backHref={routes.root}
        />
      }
    >
      <div className={styles.Content}>
        <Markdown>{data}</Markdown>
      </div>
    </BasePage>
  );
}
