const {
  compact,
  takeWhile,
  camelCase,
  capitalize,
  fromPairs,
} = require('lodash-es');

const FIELD_NAME_REGEXP = /^\*\*([^*]+)\*\*$/;
const DIVIDER_REGEXP = /^-+$/;

function parseBase(description) {
  const rows = description.split(/\n+/g);
  return compact(
    rows.map((row, i) => {
      const fieldTitleMatch = row.match(FIELD_NAME_REGEXP);
      if (fieldTitleMatch) {
        const fieldTitle = fieldTitleMatch[0];
        const text = fieldTitleMatch[1];
        const fieldName = camelCase(text);
        const fieldLabel = capitalize(text);
        const value =
          takeWhile(
            rows.slice(i + 1),
            nextRow =>
              !FIELD_NAME_REGEXP.test(nextRow) && !DIVIDER_REGEXP.test(nextRow)
          ).join('\n') || '';
        return { fieldTitle, fieldName, fieldLabel, value };
      }
      return null;
    })
  );
}

export function parseTrelloTemplate(description) {
  return parseBase(description).map(({ value, ...rest }) => ({
    placeholder: value,
    ...rest,
  }));
}

export function trelloCardDescriptionToFields(description, fieldDescriptors) {
  const parsedCard = parseBase(description);
  const cardFields = fromPairs(
    parsedCard.map(({ fieldName, value }) => [fieldName, value])
  );
  return fromPairs(
    fieldDescriptors.map(({ fieldName }) => [fieldName, cardFields[fieldName]])
  );
}

export function trelloCardFieldsToDescription(description, fieldDescriptors) {
  return fieldDescriptors
    .filter(({ fieldName }) => description[fieldName])
    .map(
      ({ fieldTitle, fieldName }) =>
        `${fieldTitle}\n${description[fieldName]}\n`
    )
    .join('\n');
}
