import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { loginUser } from '../app/redux/auth';
import { useMountEffect } from '../common/utils/hookUtils';
import { localStorageAdapter } from '../common/utils/localStorageUtils';
import { LOCAL_STORAGE_KEY_AUTH } from '../config/constants';
import LoadingPage from '../pages/LoadingPage';

export default function AppInitializedGuard({ children }) {
  const [initialized, setInitialized] = useState();
  const dispatch = useDispatch();

  useMountEffect(() => {
    const { user, accessToken, refreshToken } =
      localStorageAdapter.getItem(LOCAL_STORAGE_KEY_AUTH) || {};
    dispatch(loginUser(user, accessToken, refreshToken));
    setInitialized(true);
  });

  return initialized ? children : <LoadingPage titleId="status.initializing" />;
}
