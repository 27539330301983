import { QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './app/AppRoutes';
import { queryClient, store } from './appSingletons';
import AuthPersistenceSynchronizer from './components/AuthPersistenceSynchronizer';
import ErrorBoundary from './components/ErrorBoundary';
import { FullscreenProvider } from './components/fullscreen';
import { ScreenshotProvider } from './components/screenshots';
import LocaleProvider from './config/intl/LocaleProvider';
import AppInitializedGuard from './guards/AppInitializedGuard';

function App() {
  return (
    <LocaleProvider>
      <ErrorBoundary>
        <ReduxProvider store={store}>
          <QueryClientProvider client={queryClient}>
            <AppInitializedGuard>
              <AuthPersistenceSynchronizer />
              <ScreenshotProvider>
                <FullscreenProvider>
                  <BrowserRouter>
                    <AppRoutes />
                  </BrowserRouter>
                </FullscreenProvider>
              </ScreenshotProvider>
            </AppInitializedGuard>
          </QueryClientProvider>
        </ReduxProvider>
      </ErrorBoundary>
    </LocaleProvider>
  );
}

export default App;
