import { Form as AntForm, Button, Input } from 'antd';
import axios from 'axios';
import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router';
import * as yup from 'yup';

import apiRoutes from '../app/apiRoutes';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../common/utils/messageUtils';
import { fetchShortcutApiKeys } from '../common/utils/shortcutApiKeysUtils';
import FormField from '../components/forms/FormField';
import { BasicSwitch } from '../components/forms/booleanFields';
import { FetchingSelect } from '../components/forms/selects';

export default function ShortcutStoryForm({
  initialValues,
  upVotes,
  downVotes,
}) {
  const fetchMembers = axiosToQueryFn(apiKey =>
    axios.get(apiRoutes.getShortcutMembers, {
      headers: {
        'shortcut-api-key': apiKey,
      },
    })
  );

  const fetchWorkflows = axiosToQueryFn(apiKey =>
    axios.get(apiRoutes.getShortcutWorkflows, {
      headers: {
        'shortcut-api-key': apiKey,
      },
    })
  );

  const location = useLocation();
  const link = window.location.origin + location.pathname;

  const validationSchema = yup.object().shape({
    shortcutApiKey: yup.string().required(),
    memberId: yup.string().required(),
    workflowId: yup.string().required(),
    title: yup.string().required(),
    description: yup.string(),
  });

  const mutation = useMutation(
    axiosToQueryFn(async values => {
      const response = await axios.post(
        apiRoutes.postShortcutStory,
        {
          name: values.title,
          description: values.description,
          owner_ids: [values.memberId],
          requested_by_id: values.requesterId,
          workflow_state_id: values.workflowId,
        },
        {
          headers: {
            'shortcut-api-key': values.shortcutApiKey,
          },
        }
      );

      return response;
    })
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        let description = values.description;

        if (values.includeVoteTally) {
          description += ` \n👍 ${upVotes} 👎 ${downVotes}`;
        }
        if (values.includeLink) {
          description += ` | 🌐 <a href="${link}">Source</a>`;
        }

        values.description = description;

        try {
          await mutation.mutateAsync(values);
          showSuccessMessage(
            <FormattedMessage id="shortcut.drawer.create.story.success" />
          );
          resetForm();
        } catch (err) {
          showErrorMessage(
            <FormattedMessage id="shortcut.drawer.create.story.error" />
          );
        }
      }}
    >
      {({ handleSubmit, values }) => (
        <AntForm onFinish={handleSubmit}>
          <FormField
            name="shortcutApiKey"
            labelId="shortcut.drawer.select.workspace"
          >
            {({ field, label }) => (
              <AntForm.Item label={label}>
                <FetchingSelect
                  queryFn={fetchShortcutApiKeys}
                  queryKey="shortcutApiKeys"
                  optionMapper={apiKey => ({
                    key: apiKey._id,
                    label: apiKey.name,
                    value: apiKey.token,
                  })}
                  {...field}
                />
              </AntForm.Item>
            )}
          </FormField>
          <FormField name="memberId" labelId="shortcut.drawer.select.owner">
            {({ field, label }) => (
              <AntForm.Item label={label}>
                <FetchingSelect
                  queryFn={() => fetchMembers(values.shortcutApiKey)}
                  enabled={!!values.shortcutApiKey}
                  queryKey={['members', values.shortcutApiKey]}
                  {...field}
                />
              </AntForm.Item>
            )}
          </FormField>
          <FormField
            name="requesterId"
            labelId="shortcut.drawer.select.requester"
          >
            {({ field, label }) => (
              <AntForm.Item label={label}>
                <FetchingSelect
                  queryFn={() => fetchMembers(values.shortcutApiKey)}
                  queryKey={['requester', values.shortcutApiKey]}
                  enabled={!!values.shortcutApiKey}
                  {...field}
                />
              </AntForm.Item>
            )}
          </FormField>
          <FormField
            name="workflowId"
            labelId="shortcut.drawer.select.workflow"
          >
            {({ field, label }) => (
              <AntForm.Item label={label}>
                <FetchingSelect
                  queryFn={() => fetchWorkflows(values.shortcutApiKey)}
                  queryKey={['workflows', values.shortcutApiKey]}
                  enabled={!!values.shortcutApiKey}
                  selectFirstByDefault
                  {...field}
                />
              </AntForm.Item>
            )}
          </FormField>
          <FormField name="title" labelId="shortcut.drawer.write.title">
            {({ field, label }) => (
              <AntForm.Item label={label}>
                <Input {...field} />
              </AntForm.Item>
            )}
          </FormField>
          <FormField
            name="description"
            labelId="shortcut.drawer.write.Description"
          >
            {({ field, label }) => (
              <AntForm.Item label={label}>
                <Input.TextArea {...field} />
              </AntForm.Item>
            )}
          </FormField>
          <FormField name="includeVoteTally">
            {({ field }) => (
              <AntForm.Item>
                <BasicSwitch
                  name={field.name}
                  style={{ marginRight: '8px' }}
                  {...field}
                />
                <FormattedMessage id="shortcut.drawer.select.voteTally" />
              </AntForm.Item>
            )}
          </FormField>
          <FormField name="includeLink">
            {({ field }) => (
              <AntForm.Item>
                <BasicSwitch
                  name={field.name}
                  style={{ marginRight: '8px' }}
                  {...field}
                />
                <FormattedMessage id="shortcut.drawer.select.includeLink" />
              </AntForm.Item>
            )}
          </FormField>
          <AntForm.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={mutation.isLoading}
            >
              <FormattedMessage id="shortcut.drawer.create.story.button" />
            </Button>
          </AntForm.Item>
        </AntForm>
      )}
    </Formik>
  );
}
