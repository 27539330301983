import { createContext, useContext, useEffect, useState } from 'react';

const FullscreenContext = createContext({
  enabled: false,
});

export function FullscreenProvider({ children }) {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const fsHandler = () => {
      setEnabled(!!document.fullscreenElement);
    };
    const rHandler = () => {
      setEnabled(
        window.innerWidth === window.outerWidth &&
          window.innerHeight === window.outerHeight &&
          window.innerWidth >= 1024
      );
    };
    document.addEventListener('fullscreenchange', fsHandler);
    window.addEventListener('resize', rHandler);
    return () => {
      document.removeEventListener('fullscreenchange', fsHandler);
      window.removeEventListener('resize', rHandler);
    };
  }, []);

  return (
    <FullscreenContext.Provider value={{ enabled }}>
      {children}
    </FullscreenContext.Provider>
  );
}

export function useFullscreen() {
  const { enabled } = useContext(FullscreenContext);

  return {
    enabled,
    available: document.fullscreenEnabled,
    enable: () => !enabled && document.body.requestFullscreen(),
    disable: () => enabled && document.exitFullscreen(),
  };
}
