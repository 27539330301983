import axios from 'axios';
import { useQuery } from 'react-query';

import { axiosToQueryFn } from '../../common/utils/httpUtils';
import apiRoutes from '../apiRoutes';

const fetchUserSettings = axiosToQueryFn(() =>
  axios.get(apiRoutes.userSettings)
);

export function useUserSettings() {
  return useQuery({
    queryFn: fetchUserSettings,
    queryKey: ['userSettings'],
    retry: false,
  });
}
