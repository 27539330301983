import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import axios from 'axios';
import { isFunction } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';

import apiRoutes from '../app/apiRoutes';
import { queryClient } from '../appSingletons';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import { showConfirmModal } from '../common/utils/messageUtils';

const fetchDeleteBoard = axiosToQueryFn(boardId =>
  axios.delete(apiRoutes.deleteBoard(boardId))
);

export default function DeleteBoardButton({
  boardId,
  isOwner,
  onSuccess,
  ...rest
}) {
  const { mutate: deleteBoard, isLoading: deletingBoard } = useMutation({
    mutationFn: fetchDeleteBoard,
    onSuccess: () => {
      queryClient.refetchQueries('boards');
      if (isFunction(onSuccess)) {
        onSuccess();
      }
    },
  });

  if (!isOwner) {
    return null;
  }

  return (
    <Tooltip title={<FormattedMessage id="deleteBoard.label" />}>
      <Button
        icon={<DeleteOutlined />}
        onClick={() =>
          showConfirmModal({
            title: <FormattedMessage id="deleteBoard.confirm.title" />,
            content: <FormattedMessage id="deleteBoard.confirm.content" />,
            onOk: () => deleteBoard(boardId),
          })
        }
        loading={deletingBoard}
        disabled={deletingBoard}
        {...rest}
      >
        <span>
          <FormattedMessage id="deleteBoard.label" />
        </span>
      </Button>
    </Tooltip>
  );
}
