import { Alert } from 'antd';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import URI from 'urijs';

import apiRoutes from '../app/apiRoutes';
import { queryClient } from '../appSingletons';
import { formatError } from '../common/utils/errorUtils';
import { useMountEffect } from '../common/utils/hookUtils';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../common/utils/messageUtils';
import LoadingPage from './LoadingPage';

const fetchSetTrelloToken = axiosToQueryFn(token =>
  axios.put(apiRoutes.setTrelloToken, { token })
);

export default function TrelloConnectCallbackPage() {
  const navigate = useNavigate();

  const uri = URI();
  const hash = uri.hash();
  const { redirect } = uri.query(true);

  const { mutate, isLoading, error } = useMutation({
    mutationFn: fetchSetTrelloToken,
    onSuccess: () => {
      queryClient.refetchQueries('userSettings');
      navigate(redirect);
      showSuccessMessage(
        <FormattedMessage id="userSettings.trello.connect.success" />
      );
    },
  });

  useMountEffect(() => {
    const { token } = URI().query(hash.replace(/^#/, '')).query(true);
    if (token) {
      mutate(token);
    } else {
      navigate(redirect);
      showErrorMessage(
        <FormattedMessage id="userSettings.trello.connect.error" />
      );
    }
  });

  if (error) {
    return <Alert type="error" showIcon message={formatError(error)} />;
  }

  if (isLoading) {
    return <LoadingPage titleId="userSettings.trello.loading" />;
  }

  return null;
}
