import { identity, noop, reverse, sortBy } from 'lodash-es';
import { createContext, useContext, useState } from 'react';

const DEFAULT_SORT = { by: 'createdAt', direction: 'asc' };
const DataOptionsContext = createContext({
  sort: DEFAULT_SORT,
  setSort: noop,
});

export function useDataOptionsContext() {
  return useContext(DataOptionsContext);
}

export function DataOptionsContextProvider({ children }) {
  const [sort, setSort] = useState(DEFAULT_SORT);

  return (
    <DataOptionsContext.Provider value={{ sort, setSort }}>
      {children}
    </DataOptionsContext.Provider>
  );
}

export function makeSorter(sort) {
  return data =>
    (sort.direction === 'asc' ? identity : reverse)(sortBy(data, sort.by));
}
