import { LogoutOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import apiRoutes from '../app/apiRoutes';
import routes from '../app/routes';
import { queryClient } from '../appSingletons';
import { axiosToQueryFn } from '../common/utils/httpUtils';
import { showConfirmModal } from '../common/utils/messageUtils';

const fetchLeaveBoard = axiosToQueryFn(boardId =>
  axios.post(apiRoutes.leaveBoard(boardId))
);

export default function LeaveBoardButton({ boardId, isOwner, ...rest }) {
  const navigate = useNavigate();
  const { mutate: leaveBoard, isLoading: leavingBoard } = useMutation({
    mutationFn: fetchLeaveBoard,
    onSuccess: () => {
      queryClient.refetchQueries('boards');
      navigate(routes.defaultLogged);
    },
  });

  if (isOwner) {
    return null;
  }

  return (
    <Tooltip title={<FormattedMessage id="leaveBoard.label" />}>
      <Button
        icon={<LogoutOutlined />}
        onClick={() =>
          showConfirmModal({
            title: <FormattedMessage id="leaveBoard.confirm.title" />,
            content: <FormattedMessage id="leaveBoard.confirm.content" />,
            onOk: () => leaveBoard(boardId),
          })
        }
        loading={leavingBoard}
        disabled={leavingBoard}
        {...rest}
      >
        <span>
          <FormattedMessage id="leaveBoard.label" />
        </span>
      </Button>
    </Tooltip>
  );
}
