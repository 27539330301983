import antEn from 'antd/lib/locale-provider/en_GB';

import { readMdModule } from '../../common/utils/importUtils';
import appEn from './en.json';
import yupEn from './yup-en.json';

export const Language = {
  EN: 'EN',
};

const LanguageData = {
  [Language.EN]: {
    locale: 'en-US',
    antLocale: antEn,
    yupLocale: yupEn,
    messages: appEn,
    loadTermsOfUse: () => import('./TermsOfUse.en.md').then(readMdModule),
    loadPrivacyPolicy: () => import('./PrivacyPolicy.en.md').then(readMdModule),
  },
};

export function getLanguageData(lang) {
  return LanguageData[lang];
}
